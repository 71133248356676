var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widget-container d-flex flex-column"},[_c('div',{staticClass:"topbar d-flex"},[_c('div',[_c('div',{staticClass:"periode"},[_vm._v(_vm._s(_vm.fromDateDisplay)+" - "+_vm._s(_vm.toDateDisplay))]),_c('h1',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.$t("Widgets.WasteDistributionPyramidWidget.Headline"))+" ")])]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('widget-date-picker',{model:{value:(_vm.settings.periodeFilter),callback:function ($$v) {_vm.$set(_vm.settings, "periodeFilter", $$v)},expression:"settings.periodeFilter"}})],1)]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.loading || _vm.distributionData == null)?_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100"},[_c('b-spinner')],1):_c('div',{staticClass:"pyramid-container"},[_c('div',{staticClass:"pyramid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 bar-wrap"},[_c('div',{staticClass:"bar"},[_c('span',[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"12","cy":"12","r":"12","fill":"#438651"}}),_c('path',{attrs:{"d":"M16.2177 13.2099L16.8544 7.34195C16.9268 6.67428 16.3336 6.12642 15.6738 6.2516L10.2192 7.28644C6.15926 8.05669 4.69404 13.1934 7.31485 16.21C8.04194 14.5733 9.56351 12.242 11.7998 10.6437C9.8016 14.487 9.13874 16.0096 8.93893 17.4813C12.0015 19.0249 15.8083 16.9832 16.2177 13.2099Z","fill":"white"}})]),_vm._v(" "+_vm._s(_vm.$t("Widgets.WasteDistributionPyramidWidget.ReuseCategory"))+" ")])])]),_c('div',{staticClass:"col-12 col-md-6 info"},[_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.distributionData.reusePercentage))+" [%] ")]),_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.distributionData.reuseTons))+" [tons] ")]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                ("Transport: " + (_vm.distributionData.reuseTransportCO2.toFixed(
                  2
                )) + " [CO2]")
              ),expression:"\n                `Transport: ${distributionData.reuseTransportCO2.toFixed(\n                  2\n                )} [CO2]`\n              ",modifiers:{"hover":true,"top":true}}]},[_vm._v(_vm._s(_vm._f("toCurrency")((_vm.distributionData.reuseCO2 + _vm.distributionData.reuseTransportCO2)))+" [CO2] ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 bar-wrap"},[_c('div',{staticClass:"bar"},[_c('span',[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"12","cy":"12","r":"12","fill":"#3C7A49"}}),_c('path',{attrs:{"d":"M13.9695 10.7258H16.9999V7.73099","stroke":"white","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M7 8.60812C7.51591 8.09828 8.12838 7.69386 8.80244 7.41794C9.47651 7.14201 10.199 7 10.9286 7C11.6582 7 12.3806 7.14201 13.0547 7.41794C13.7288 7.69386 14.3412 8.09828 14.8571 8.60812L17 10.7258","stroke":"white","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M10.0305 13.2551H7V16.2499","stroke":"white","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M17 15.3728C16.4841 15.8826 15.8716 16.2871 15.1976 16.563C14.5235 16.8389 13.801 16.9809 13.0714 16.9809C12.3418 16.9809 11.6194 16.8389 10.9453 16.563C10.2712 16.2871 9.65876 15.8826 9.14286 15.3728L7 13.2552","stroke":"white","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" "+_vm._s(_vm.$t("Widgets.WasteDistributionPyramidWidget.RezyclCategory"))+" ")])])]),_c('div',{staticClass:"col-12 col-md-6 info"},[_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.distributionData.rezyclPercentage))+" [%] ")]),_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.distributionData.rezyclTons))+" [tons] ")]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                ("Transport: " + (_vm.distributionData.rezyclTransportCO2.toFixed(
                  2
                )) + " [CO2]")
              ),expression:"\n                `Transport: ${distributionData.rezyclTransportCO2.toFixed(\n                  2\n                )} [CO2]`\n              ",modifiers:{"hover":true,"top":true}}]},[_vm._v(_vm._s(_vm._f("toCurrency")((_vm.distributionData.rezyclCO2 + _vm.distributionData.rezyclTransportCO2)))+" [CO2] ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 bar-wrap"},[_c('div',{staticClass:"bar"},[_c('span',[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"12","cy":"12","r":"12","fill":"#305E38"}}),_c('path',{attrs:{"d":"M10.5 10.8L8.34886 9.14263C7.58199 10.3458 7 11.7647 7 13.3C7 14.6261 7.52678 15.8978 8.46447 16.8355C9.40215 17.7732 10.6739 18.3 12 18.3C13.3261 18.3 14.5979 17.7732 15.5355 16.8355C16.4732 15.8978 17 14.6261 17 13.3C17 8.79999 12.5 5.29999 12.5 5.29999L10.5 10.8Z","fill":"white"}})]),_vm._v(" "+_vm._s(_vm.$t( "Widgets.WasteDistributionPyramidWidget.CombostionCategory" ))+" ")])])]),_c('div',{staticClass:"col-12 col-md-6 info"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.distributionData.combostionPercentage))+" [%] ")]),_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.distributionData.combostionTons))+" [tons] ")]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                ("Transport: " + (_vm.distributionData.combostionTransportCO2.toFixed(
                  2
                )) + " [CO2]")
              ),expression:"\n                `Transport: ${distributionData.combostionTransportCO2.toFixed(\n                  2\n                )} [CO2]`\n              ",modifiers:{"hover":true,"top":true}}]},[_vm._v(_vm._s(_vm._f("toCurrency")((_vm.distributionData.combostionCO2 + _vm.distributionData.combostionTransportCO2)))+" [CO2] ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 bar-wrap"},[_c('div',{staticClass:"bar"},[_c('span',[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"12","cy":"12","r":"12","fill":"#234328"}}),_c('path',{attrs:{"d":"M10 11H12V18","stroke":"white","stroke-width":"3","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8Z","fill":"white"}})]),_vm._v(" "+_vm._s(_vm.$t("Widgets.WasteDistributionPyramidWidget.SpecialCategory"))+" ")])])]),_c('div',{staticClass:"col-12 col-md-6 info"},[_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.distributionData.specialPercentage))+" [%] ")]),_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.distributionData.specialTons))+" [tons] ")]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                ("Transport: " + (_vm.distributionData.specialTransportCO2.toFixed(
                  2
                )) + " [CO2]")
              ),expression:"\n                `Transport: ${distributionData.specialTransportCO2.toFixed(\n                  2\n                )} [CO2]`\n              ",modifiers:{"hover":true,"top":true}}]},[_vm._v(_vm._s(_vm._f("toCurrency")((_vm.distributionData.specialCO2 + _vm.distributionData.specialTransportCO2)))+" [CO2] ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 bar-wrap"},[_c('div',{staticClass:"bar"},[_c('span',[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"12","cy":"12","r":"12","fill":"#16291A"}}),_c('path',{attrs:{"d":"M17.9783 15C18.1817 15 18.2999 14.7702 18.1817 14.6047L13.6675 8.28481C13.5678 8.14522 13.3604 8.14522 13.2607 8.28481L11.4053 10.8824L12.4641 13L11.147 11.2439L11.1405 11.2531L9.66468 9.26958C9.56471 9.13522 9.36349 9.13522 9.26353 9.26958L5.29703 14.6008C5.17433 14.7657 5.29204 15 5.49761 15H17.9783Z","fill":"white"}})]),_vm._v(" "+_vm._s(_vm.$t( "Widgets.WasteDistributionPyramidWidget.LandfillCategory" ))+" ")])])]),_c('div',{staticClass:"col-12 col-md-6 info"},[_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.distributionData.landfillPercentage))+" [%] ")]),_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.distributionData.landfillTons))+" [tons] ")]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                ("Transport: " + (_vm.distributionData.landfillTransportCO2.toFixed(
                  2
                )) + " [CO2]")
              ),expression:"\n                `Transport: ${distributionData.landfillTransportCO2.toFixed(\n                  2\n                )} [CO2]`\n              ",modifiers:{"hover":true,"top":true}}]},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.distributionData.landfillCO2 + _vm.distributionData.landfillTransportCO2))+" [CO2] ")])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }