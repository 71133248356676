import BaseService from "../base/base.service";

export default class DashboardLayoutService extends BaseService {
    constructor() {
        super('Dashboard/Layout')
    }

    async getLayout()
    {
        return (await this.get('/')).data.data;
    }

    async saveLayout(layout)
    {
        return await this.post('/', {layout: layout});
    }
}