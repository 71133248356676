<template>
  <div class="widget-container d-flex flex-column">
    <div class="topbar d-flex">
      <div>
        <div class="periode">
          Periode: {{ fromDateDisplay }} - {{ toDateDisplay }}
        </div>
        <h1 class="header">
          {{ selectedLocation == null ? "" : selectedLocation.name }}
        </h1>
        <div class="radios d-flex">{{ dataTypeDisplay[dataType] }}</div>
      </div>
      <div class="d-flex justify-content-end">
        <widget-date-picker @toDate="toDateUpdate" @fromDate="fromDateUpdate" />
      </div>
    </div>
    <div class="bar-container d-flex">
      <div v-for="value in values" :key="value.id" class="d-flex flex-column">
        <div class="value">
          {{ value.amount | toCurrency }}
        </div>
        <div class="bar-content d-flex flex-column">
          <div class="bars d-flex flex-column">
            <div
              :style="{ height: getHeight(value.amount) }"
              :class="{ zero: value.amount == 0 }"
              class="bar"
            ></div>
            <div
              :style="{ height: getHeight(value.amount / 3) }"
              :class="{ zero: value.amount == 0 }"
              class="bar bar-before"
            ></div>
          </div>
          <div v-b-tooltip.hover.top="value.name" class="name">
            {{ value.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex options">
      <select
        @change="fetchWidget"
        class="form-control"
        v-model="selectedLocation"
      >
        <option
          :value="location"
          v-for="location in locations"
          :key="location.id"
        >
          {{ location.name }}
        </option>
      </select>
      <select class="form-control" v-model="dataType">
        <option value="rent">Material rent</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import WidgetDatePicker from "@/views/dashboard/components/WidgetDatePicker.vue";
import WidgetService from "@/services/dashboard/widgets/widgetService-service";
import moment from "moment";
import { VBTooltip } from "bootstrap-vue";

export default {
  components: {
    WidgetDatePicker,
    VBTooltip,
  },
  async created() {
    this.services = new WidgetService();
    this.fetchLocations({include: ["infoCards"] });
    await this.fetchWidget();
  },
  data() {
    return {
      fromDate: null,
      toDate: null,
      selectedLocation: null,
      services: null,
      dataType: "rent",
      dataTypeDisplay: {
        rent: "Material rent",
      },
      values: [],
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapGetters({
      locations: "locationsOld/list",
    }),
    fromDateDisplay() {
      return moment(this.fromDate).format("MMM DD, YYYY");
    },
    toDateDisplay() {
      return moment(this.toDate).format("MMM DD, YYYY");
    },
    max() {
      const max = this.values.reduce(function (prev, current) {
        return prev.amount > current.amount ? prev : current;
      });

      return max.amount;
    },
  },
  methods: {
    ...mapActions({
      fetchLocations: "locationsOld/fetchList",
    }),
    fromDateUpdate(date) {
      this.fromDate = date;
      this.fetchWidget();
    },
    toDateUpdate(date) {
      this.toDate = date;
      this.fetchWidget();
    },
    async fetchWidget() {
      if (!this.fromDate || !this.toDate) return;

      let location = null;
      if (this.selectedLocation != null) {
        location = this.selectedLocation.id;
      }
      // let resonse = await this.services.getDataWithLocation(
      //   "/wastefraction/" + this.dataType,
      //   location,
      //   this.fromDate,
      //   this.toDate
      // );
      let resonse = await this.services.getDataWithLocation(
        "/wastefraction/ton",
        location,
        this.fromDate,
        this.toDate
      );
      this.values = resonse;
    },
    getHeight(value) {
      if (value == 0 || !this.max) return 1 + "%";
      let p = value / this.max;
      let result = Math.round(p * 100);

      return result + "%";
    },
  },
  watch: {
    locations(newValue, oldValue) {
      if (this.selectedLocation == null) {
        this.selectedLocation = this.locations[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-container {
  height: 100%;
  justify-content: space-between;
  padding: 10px;
  .options {
    justify-content: space-between;
    > select {
      width: 49%;
    }
  }
  .topbar {
    > div {
      width: 50%;
    }
    .radios {
      > * {
        input {
          margin-right: 3px;
        }
        padding: 0 5px;
      }
    }
  }

  .bar-container {
    height: 100%;
    padding: 10px 30px;
    .bar-content {
      height: 100%;
      align-items: center;
      justify-content: flex-end;
      .bars {
        height: 100%;
        position: relative;
        justify-content: flex-end;
      }
    }
    > div {
      width: 80px;
      height: 100%;
      justify-content: space-between;
      margin-right: 20px;
      text-align: center;
      align-items: center;
    }
    .bar {
      &.zero {
        background-color: #ccc;
        height: 1%;
      }
      &.bar-before {
        &.zero {
          display: none;
        }
        position: absolute;
        left: 50%;
        z-index: 1;
        background-color: #ccc;
      }
      z-index: 2;
      width: 40px;
      background-color: #407e3d;
      border-radius: 5px;
    }
    .value {
      margin-bottom: 10px;
    }
    .name {
      font-size: 12px;
      font-weight: bold;
      max-width: 80px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>