<template>
  <v-chart :autoresize="true" class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  props: {
    chartHeaders: {
      required: true,
      default: () => {
        return new Array();
      },
    },

    chartSeries: {
      required: true,
      default: () => {
        return new Array();
      },
    },
    axisLabel: {
      required: false,
      default: () => {
        return new Object();
      },
    },
    grid: {
      required: false,
      default: () => {
        return new Object();
      },
    },
  },
  data() {
    return {};
  },
  components: {
    VChart,
  },
  computed: {
    labelOptions() {
      return;
    },
    option() {
      let options = {
        tooltip: {
          trigger: "item",
          valueFormatter: (value) => this.$options.filters.toCurrency(value),
        },
        grid: this.grid,
        xAxis: [
          {
            type: "category",
            data: this.chartHeaders,
            axisLabel: this.axisLabel,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: this.chartSeries,
      };

      return options;
    },
  },
};
</script>

<style>
</style>