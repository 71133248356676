<template>
  <div class="widget-container d-flex flex-column">
    <div class="topbar d-flex">
      <div>
        <div class="periode">{{ fromDateDisplay }} - {{ toDateDisplay }}</div>
        <h1 class="header">
          {{ $t("Widgets.WasteDistributionPyramidWidget.Headline") }}
        </h1>
      </div>
      <div class="d-flex justify-content-end">
        <widget-date-picker v-model="settings.periodeFilter" />
      </div>
    </div>
    <div class="circle-container" v-if="!loading">
      <v-chart class="chart" :option="option" />

      <div class="info-container">
        <ul>
          <li>
            <span class="widget_color economy_color"></span>
            {{ $t("Widgets.WasteFractionWidget.Economy") }}
          </li>
          <li>
            <span class="widget_color tons_color"></span>
            {{ $t("Widgets.WasteFractionWidget.Tons") }}
          </li>
          <li>
            <span class="widget_color co2_color"></span>
            {{ $t("Widgets.WasteFractionWidget.CO2") }}
          </li>
          <br />
          <li>
            <svg width="24" height="24">
              <path
                d="M16.2177 13.2099L16.8544 7.34195C16.9268 6.67428 16.3336 6.12642 15.6738 6.2516L10.2192 7.28644C6.15926 8.05669 4.69404 13.1934 7.31485 16.21C8.04194 14.5733 9.56351 12.242 11.7998 10.6437C9.8016 14.487 9.13874 16.0096 8.93893 17.4813C12.0015 19.0249 15.8083 16.9832 16.2177 13.2099Z"
                fill="gray"
              />
            </svg>
            {{ $t("Widgets.WasteDistributionPyramidWidget.ReuseCategory") }}
          </li>
          <li>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M13.9695 10.7258H16.9999V7.73099"
                stroke="gray"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 8.60812C7.51591 8.09828 8.12838 7.69386 8.80244 7.41794C9.47651 7.14201 10.199 7 10.9286 7C11.6582 7 12.3806 7.14201 13.0547 7.41794C13.7288 7.69386 14.3412 8.09828 14.8571 8.60812L17 10.7258"
                stroke="gray"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.0305 13.2551H7V16.2499"
                stroke="gray"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17 15.3728C16.4841 15.8826 15.8716 16.2871 15.1976 16.563C14.5235 16.8389 13.801 16.9809 13.0714 16.9809C12.3418 16.9809 11.6194 16.8389 10.9453 16.563C10.2712 16.2871 9.65876 15.8826 9.14286 15.3728L7 13.2552"
                stroke="gray"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ $t("Widgets.WasteDistributionPyramidWidget.RezyclCategory") }}
          </li>
          <li>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M10.5 10.8L8.34886 9.14263C7.58199 10.3458 7 11.7647 7 13.3C7 14.6261 7.52678 15.8978 8.46447 16.8355C9.40215 17.7732 10.6739 18.3 12 18.3C13.3261 18.3 14.5979 17.7732 15.5355 16.8355C16.4732 15.8978 17 14.6261 17 13.3C17 8.79999 12.5 5.29999 12.5 5.29999L10.5 10.8Z"
                fill="gray"
              />
            </svg>
            {{
              $t("Widgets.WasteDistributionPyramidWidget.CombostionCategory")
            }}
          </li>
          <li>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M10 11H12V18"
                stroke="gray"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8Z"
                fill="gray"
              />
            </svg>
            {{ $t("Widgets.WasteDistributionPyramidWidget.SpecialCategory") }}
          </li>
          <li>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M17.9783 15C18.1817 15 18.2999 14.7702 18.1817 14.6047L13.6675 8.28481C13.5678 8.14522 13.3604 8.14522 13.2607 8.28481L11.4053 10.8824L12.4641 13L11.147 11.2439L11.1405 11.2531L9.66468 9.26958C9.56471 9.13522 9.36349 9.13522 9.26353 9.26958L5.29703 14.6008C5.17433 14.7657 5.29204 15 5.49761 15H17.9783Z"
                fill="gray"
              />
            </svg>
            {{ $t("Widgets.WasteDistributionPyramidWidget.LandfillCategory") }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormDatepicker, BSpinner } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import WidgetDatePicker from "@/views/dashboard/components/WidgetDatePicker.vue";
import moment from "moment";
import WasteDistributionCircleWidgetService from "@/services/dashboard/widgets/wasteDistributionCircleWidget-service";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  props: {
    settings: {
      required: true,
    },
  },
  components: {
    BFormDatepicker,
    BSpinner,
    WidgetDatePicker,
    BSpinner,
    VChart,
  },
  data() {
    return {
      distributionData: {
        rezyclTons: 0,
        combostionTons: 0,
        specialTons: 0,
        landfillTons: 0,
        reuseTons: 0,

        rezyclCO2: 0,
        combostionCO2: 0,
        specialCO2: 0,
        landfillCO2: 0,
        reuseCO2: 0,

        rezyclExpenses: 0,
        combostionExpenses: 0,
        specialExpenses: 0,
        landfillExpenses: 0,
        reuseExpenses: 0,
      },
      loading: false,
    };
  },
  created() {
    this.fetchWidget();
  },

  computed: {
    ...mapGetters({
      fromDateFilter: "wasteDistributionCircleWidget/getFromDateFilter",
      toDateFilter: "wasteDistributionCircleWidget/getToDateFilter",
    }),

    option() {
      const self = this;
      let options = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `${self.$options.filters.toCurrency(params.data.value)}${
              params.data.unit
            } (${self.$options.filters.toCurrency(params.percent)}%)`;
          },
        },
        series: [
          {
            type: "pie",
            selectedMode: "single",
            radius: ["25%", "49%"],
            label: {
              position: "inner",
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
              color: "#234328",
            },

            data: [
              {
                value: this.distributionData.reuseCO2,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/reusesvg.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[tons]",
              },
              {
                value: this.distributionData.rezyclCO2,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/rezycl.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[tons]",
              },
              {
                value: this.distributionData.combostionCO2,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/CombostionCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[Tons]",
              },
              {
                value: this.distributionData.specialCO2,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/SpecialCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[Tons]",
              },
              {
                value: this.distributionData.landfillCO2,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/LandfillCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[Tons]",
              },
            ],
          },
          {
            type: "pie",
            selectedMode: "single",
            radius: ["50%", "74%"],
            label: {
              position: "inner",
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
              color: "#305e38",
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.distributionData.reuseTons,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/reusesvg.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[tons]",
              },
              {
                value: this.distributionData.rezyclTons,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/rezycl.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[tons]",
              },
              {
                value: this.distributionData.combostionTons,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/CombostionCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[tons]",
              },
              {
                value: this.distributionData.specialTons,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/SpecialCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[tons]",
              },
              {
                value: this.distributionData.landfillTons,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/LandfillCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[tons]",
              },
            ],
          },
          {
            type: "pie",
            radius: ["75%", "100%"],
            label: {
              position: "inner",
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
              color: "#438651",
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.distributionData.reuseExpenses,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/reusesvg.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[kr.]",
              },
              {
                value: this.distributionData.rezyclExpenses,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/rezycl.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[kr.]",
              },
              {
                value: this.distributionData.combostionExpenses,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/CombostionCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[kr.]",
              },
              {
                value: this.distributionData.specialExpenses,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/SpecialCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[kr.]",
              },
              {
                value: this.distributionData.landfillExpenses,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/LandfillCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: "[kr.]",
              },
            ],
          },
        ],
      };
      return options;
    },
    periodeFilterSetting() {
      if (
        this.settings.periodeFilter == undefined ||
        this.settings.periodeFilter.showLastTwelvMonths
      ) {
        let toDate = new Date();
        let fromDate = new Date();
        fromDate = fromDate.setFullYear(toDate.getFullYear() - 1);

        return {
          periode: [
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
          ],
          showLastTwelvMonths: true,
        };
      } else {
        return this.settings.periodeFilter;
      }
    },
    fromDate() {
      return this.periodeFilterSetting.periode[0];
    },

    toDate() {
      return this.periodeFilterSetting.periode[1];
    },

    fromDateDisplay() {
      return moment(this.fromDate).format("MMM DD, YYYY");
    },
    toDateDisplay() {
      return moment(this.toDate).format("MMM DD, YYYY");
    },
  },

  methods: {
    ...mapActions({
      fetchDistributionData:
        "wasteDistributionCircleWidget/fetchDistributionData",
      setFromDateFilter: "wasteDistributionCircleWidget/updateFromDateFilter",
      setToDateFilter: "wasteDistributionCircleWidget/updateToDateFilter",
    }),
    getTonsFromKg(kg) {
      return (kg / 1000).toFixed(3);
    },
    toDateUpdate(value) {
      this.toDate = value;
    },
    fromDateUpdate(value) {
      this.fromDate = value;
    },
    async fetchWidget() {
      try {
        this.loading = true;
        const service = new WasteDistributionCircleWidgetService();
        this.distributionData = await service.getDistributionData(
          this.fromDate,
          this.toDate
        );
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    periodeFilterSetting() {
      this.fetchWidget();
    },
  },
};
</script>
<style lang="scss" scoped>
ul > li {
  display: flex;
  text-decoration: none;
  list-style: none;
  margin-bottom: 5px;
  align-items: center;
}
.widget_color {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: block;
  margin-right: 5px;
}
.economy_color {
  background-color: #438651;
}
.tons_color {
  background-color: #305e38;
}
.co2_color {
  background-color: #234328;
}
.widget-container {
  height: 100%;
  justify-content: space-between;
  padding: 10px;
  .topbar {
    > div {
      width: 50%;
    }
    .radios {
      > * {
        input {
          margin-right: 3px;
        }
        padding: 0 5px;
      }
    }
  }

  .bar-container {
    height: 100%;
    padding: 10px 30px;
    .bar-content {
      height: 100%;
      align-items: center;
      justify-content: flex-end;
    }
    > div {
      width: 80px;
      height: 100%;
      justify-content: space-between;
      margin-right: 20px;
      text-align: center;
      align-items: center;
    }
    .bar {
      &.zero {
        background-color: #ccc;
        height: 1%;
      }
      width: 40px;
      background-color: #407e3d;
      border-radius: 5px;
    }
    .value {
      margin-bottom: 10px;
    }
    .name {
      font-size: 12px;
      font-weight: bold;
    }
  }
  .circle-container {
    display: flex;
    width: 100%;
    height: 100%;
    > div {
      width: 30%;
    }
    .info-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>