<template>
  <div class="wrapper d-flex">
    <transition name="fade">
      <div v-show="datePickerVisible">
        <date-picker
          ref="datePickerInput"
          v-model="content.periode"
          valueType="format"
          range
          @change="handleDateRangeChanged"
          :open.sync="datePickerVisible"
        />
      </div>
    </transition>
    <div
      @click="toggleShowLastTwelvMonths"
      class="container mr-1"
      :class="{ active: content.showLastTwelvMonths == true }"
      v-show="!datePickerVisible"
      v-b-tooltip.hover.top="'Always show last 12 months from todays date'"
    >
      <feather-icon size="16" icon="RadioIcon" />
    </div>
    <div
      @click="toggleDates"
      class="container"
      :class="{ active: content.showLastTwelvMonths == false }"
    >
      <feather-icon size="16" icon="CalendarIcon" />
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { VBTooltip } from "bootstrap-vue";
import moment from "moment";
export default {
  components: { DatePicker, VBTooltip },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      datePickerVisible: false,
      content: this.value,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {
    if (this.content == undefined) {
      this.content = { periode: [], showLastTwelvMonths: true };
      this.handleInput();
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", JSON.parse(JSON.stringify(this.content)));
    },
    handleDateRangeChanged() {
      this.content.showLastTwelvMonths = false;
      this.handleInput();
    },
    toggleDates() {
      this.datePickerVisible = !this.datePickerVisible;
    },
    toggleShowLastTwelvMonths() {
      this.content.showLastTwelvMonths = !this.content.showLastTwelvMonths;
      this.handleInput();
    },
  },
  watch: {
    datePickerVisible(value) {
      if (!value) return;
    },
  },
};
</script>
<style  lang="scss">
.mx-datepicker-range {
  width: 220px;
}
.mx-input-wrapper {
  height: 38px;
  margin-right: 8px;
  input {
    height: 38px;
  }
}
.mx-input:hover,
.mx-input:focus {
  border-color: #407e3d;
}
.mx-calendar-content .cell.active {
  background-color: #407e3d;
}
.mx-table-date .today {
  color: #407e3d;
}

.mx-calendar-content .cell.in-range,
.mx-calendar-content .cell.hover-in-range {
  background-color: white;
}

.mx-calendar-content .cell:hover {
  background-color: #407e3d;
  color: white;
}
</style>
<style lang="scss" scoped>
.wrapper {
  .date-container {
    > input {
      width: 49%;
    }
    &.flex-column {
      > input {
        width: 100%;
      }
    }
    justify-content: space-between;
    padding: 0 5px;

    position: absolute;
    left: 0px;
    top: 65px;
    padding: 20px 10px;
    background: white;
    width: 100%;
    overflow: hidden;
    height: calc(100% - 65px);
  }
  .container {
    &.active {
      background-color: #407e3d;
      color: white;
    }
    cursor: pointer;
    margin: 0;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 38px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>