<template>
  <div class="widget-container d-flex flex-column">
    <div class="topbar d-flex">
      <div>
        <div class="periode">{{ fromDateDisplay }} - {{ toDateDisplay }}</div>
      </div>
      <div class="d-flex justify-content-end">
        <select
          class="form-control mr-1"
          v-model="settings.wasteCategory"
          @change="fetchWidget"
        >
          <option disabled>Compare to</option>
          <option value="Combostion">
            {{ $t("Generic.WasteCategoryCombostion") }}
          </option>
          <option value="Landfill">
            {{ $t("Generic.WasteCategoryLandfill") }}
          </option>
          <option value="Special">
            {{ $t("Generic.WasteCategorySpecial") }}
          </option>
          <option value="Rezycl">
            {{ $t("Generic.WasteCategoryRezycl") }}
          </option>
          <option value="Reuse">{{ $t("Generic.WasteCategoryReuse") }}</option>
        </select>
        <widget-date-picker v-model="settings.periodeFilter" />
      </div>
    </div>

    <b-row class="h-100">
      <b-col cols="7">
        <transition name="fade" mode="out-in">
          <div
            v-if="loading || data == null"
            class="d-flex justify-content-center align-items-center h-100"
          >
            <b-spinner />
          </div>
          <b-table-lite
            class="mt-1"
            v-else
            fixed
            hover
            :fields="tableFields"
            :items="tableContent"
          >
            <template #cell(category)="data">
              <small>
                <svg
                  v-if="data.value == 'WasteCategoryReuse'"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="12" fill="#438651" />
                  <path
                    d="M16.2177 13.2099L16.8544 7.34195C16.9268 6.67428 16.3336 6.12642 15.6738 6.2516L10.2192 7.28644C6.15926 8.05669 4.69404 13.1934 7.31485 16.21C8.04194 14.5733 9.56351 12.242 11.7998 10.6437C9.8016 14.487 9.13874 16.0096 8.93893 17.4813C12.0015 19.0249 15.8083 16.9832 16.2177 13.2099Z"
                    fill="white"
                  />
                </svg>
                <svg
                  v-else-if="data.value == 'WasteCategoryRezycl'"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="12" fill="#3C7A49" />
                  <path
                    d="M13.9695 10.7258H16.9999V7.73099"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 8.60812C7.51591 8.09828 8.12838 7.69386 8.80244 7.41794C9.47651 7.14201 10.199 7 10.9286 7C11.6582 7 12.3806 7.14201 13.0547 7.41794C13.7288 7.69386 14.3412 8.09828 14.8571 8.60812L17 10.7258"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.0305 13.2551H7V16.2499"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17 15.3728C16.4841 15.8826 15.8716 16.2871 15.1976 16.563C14.5235 16.8389 13.801 16.9809 13.0714 16.9809C12.3418 16.9809 11.6194 16.8389 10.9453 16.563C10.2712 16.2871 9.65876 15.8826 9.14286 15.3728L7 13.2552"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <svg
                  v-else-if="data.value == 'WasteCategoryCombostion'"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="12" fill="#305E38" />
                  <path
                    d="M10.5 10.8L8.34886 9.14263C7.58199 10.3458 7 11.7647 7 13.3C7 14.6261 7.52678 15.8978 8.46447 16.8355C9.40215 17.7732 10.6739 18.3 12 18.3C13.3261 18.3 14.5979 17.7732 15.5355 16.8355C16.4732 15.8978 17 14.6261 17 13.3C17 8.79999 12.5 5.29999 12.5 5.29999L10.5 10.8Z"
                    fill="white"
                  />
                </svg>
                <svg
                  v-else-if="data.value == 'WasteCategorySpecial'"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="12" fill="#234328" />
                  <path
                    d="M10 11H12V18"
                    stroke="white"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8Z"
                    fill="white"
                  />
                </svg>
                <svg
                  v-else-if="data.value == 'WasteCategoryLandfill'"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="12" fill="#16291A" />
                  <path
                    d="M17.9783 15C18.1817 15 18.2999 14.7702 18.1817 14.6047L13.6675 8.28481C13.5678 8.14522 13.3604 8.14522 13.2607 8.28481L11.4053 10.8824L12.4641 13L11.147 11.2439L11.1405 11.2531L9.66468 9.26958C9.56471 9.13522 9.36349 9.13522 9.26353 9.26958L5.29703 14.6008C5.17433 14.7657 5.29204 15 5.49761 15H17.9783Z"
                    fill="white"
                  />
                </svg>
                <span> {{ $t("Generic." + data.value) }}</span>
              </small>
            </template>
            <template #cell(share)="data">
              <small>
                <div class="form-control-sm d-flex align-items-center">
                  {{ data.value | toCurrency }}%
                </div></small
              >
            </template>
            <template #cell(tonsCO2)="data">
              <small> {{ data.value | toCurrency }}</small>
            </template>
            <template #cell(tonsCO2Simulated)="data">
              <small>{{ data.value | toCurrency }}</small>
            </template>
            <template #cell(saving)="data">
              <span
                :class="{
                  'text-primary': data.value > 0,
                  'text-danger': data.value < 0,
                }"
                >{{ data.value | toCurrency }}</span
              >
            </template>
          </b-table-lite>
        </transition>
      </b-col>
      <b-col cols="5" style="height: 300px">
        <div
          class="d-flex justify-content-between"
          style="height: 100%"
          v-if="!loading"
        >
          <pie-chart
            :title="currentTonsPriceCo2Title"
            :dataItems="currentTonsCo2Chart"
            :subTitle="$t('Generic.TonsCO2ChartTitle')"
          ></pie-chart>
          <pie-chart
            :title="SavingTonsPriceCo2Title"
            :dataItems="savingTonsCo2Chart"
            :subTitle="'Total besparelse'"
          ></pie-chart>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="show-pr-unit-box">
          <h3>Vis pr enhed</h3>
          <div class="unit-picker d-flex">
            <input
              class="form-control form-control-sm mr-1"
              placeholder="antal"
              type="number"
              v-model="settings.unitAmount"
            />
            <input
              class="form-control form-control-sm"
              placeholder="enhed"
              v-model="settings.unit"
            />
          </div>
          <div class="d-flex mt-1 justify-content-between">
            <span class="font-weight-bold">
              <span>Co2 pr. {{ settings.unit }}: </span>
              <span v-if="settings.unitAmount > 0 && data">{{
                (data.totalCO2 / settings.unitAmount).toFixed(4).replace(".", ",")
              }}</span>
              <span v-else>--</span>
            </span>
            <span
            v-if="data"
              :class="{
                'text-primary': data.totalCO2 - data.totalCO2Simulated > 0,
                'text-danger': data.totalCO2 - data.totalCO2Simulated <= 0,
              }"
              class="font-weight-bold"
            >
              <span>Potentiel besparelse pr. {{ settings.unit }}: </span>
              <span v-if="settings.unitAmount > 0">{{
                ((data.totalCO2 - data.totalCO2Simulated) / settings.unitAmount).toFixed(4).replace(".", ",")
              }}</span>
              <span v-else>--</span>
            </span>
          </div>
        </div>
      </b-col>
    </b-row>
    <div>
      <select
        class="form-control"
        v-model="settings.selectedLocation"
        @change="fetchWidget"
      >
        <option :value="null">
          {{ $t("Widgets.WasteFractionWidget.AllLocations") }}
        </option>
        <option
          :value="location"
          v-for="location in locations"
          :key="location.id"
        >
          {{ location.name }}
        </option>
      </select>
    </div>
  </div>
  <!--
  <div class="widget-container d-flex flex-column">
  
    <div>
      <transition name="fade" mode="out-in">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center h-100"
        >
          <b-spinner />
        </div>
        <div v-else>
          <div>
            <b-table-lite
              fixed
              hover
              :fields="tableFields"
              :items="tableContent"
            >
              <template #cell(category)="data">
                <small>
                  <svg
                    v-if="data.value == 'WasteCategoryReuse'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#438651" />
                    <path
                      d="M16.2177 13.2099L16.8544 7.34195C16.9268 6.67428 16.3336 6.12642 15.6738 6.2516L10.2192 7.28644C6.15926 8.05669 4.69404 13.1934 7.31485 16.21C8.04194 14.5733 9.56351 12.242 11.7998 10.6437C9.8016 14.487 9.13874 16.0096 8.93893 17.4813C12.0015 19.0249 15.8083 16.9832 16.2177 13.2099Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    v-else-if="data.value == 'WasteCategoryRezycl'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#3C7A49" />
                    <path
                      d="M13.9695 10.7258H16.9999V7.73099"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 8.60812C7.51591 8.09828 8.12838 7.69386 8.80244 7.41794C9.47651 7.14201 10.199 7 10.9286 7C11.6582 7 12.3806 7.14201 13.0547 7.41794C13.7288 7.69386 14.3412 8.09828 14.8571 8.60812L17 10.7258"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.0305 13.2551H7V16.2499"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 15.3728C16.4841 15.8826 15.8716 16.2871 15.1976 16.563C14.5235 16.8389 13.801 16.9809 13.0714 16.9809C12.3418 16.9809 11.6194 16.8389 10.9453 16.563C10.2712 16.2871 9.65876 15.8826 9.14286 15.3728L7 13.2552"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    v-else-if="data.value == 'WasteCategoryCombostion'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#305E38" />
                    <path
                      d="M10.5 10.8L8.34886 9.14263C7.58199 10.3458 7 11.7647 7 13.3C7 14.6261 7.52678 15.8978 8.46447 16.8355C9.40215 17.7732 10.6739 18.3 12 18.3C13.3261 18.3 14.5979 17.7732 15.5355 16.8355C16.4732 15.8978 17 14.6261 17 13.3C17 8.79999 12.5 5.29999 12.5 5.29999L10.5 10.8Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    v-else-if="data.value == 'WasteCategorySpecial'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#234328" />
                    <path
                      d="M10 11H12V18"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    v-else-if="data.value == 'WasteCategoryLandfill'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#16291A" />
                    <path
                      d="M17.9783 15C18.1817 15 18.2999 14.7702 18.1817 14.6047L13.6675 8.28481C13.5678 8.14522 13.3604 8.14522 13.2607 8.28481L11.4053 10.8824L12.4641 13L11.147 11.2439L11.1405 11.2531L9.66468 9.26958C9.56471 9.13522 9.36349 9.13522 9.26353 9.26958L5.29703 14.6008C5.17433 14.7657 5.29204 15 5.49761 15H17.9783Z"
                      fill="white"
                    />
                  </svg>
                  <span> {{ $t("Generic." + data.value) }}</span>
                </small>
              </template>
              <template #cell(share)="data">
                <div class="form-control-sm d-flex align-items-center">
                  {{ data.value | toCurrency }}%
                </div>
              </template>
              <template #cell(tonsCO2)="data">
                {{ data.value | toCurrency }}
              </template>
              <template #cell(tonsCO2Simulated)="data">
                {{ data.value | toCurrency }}
              </template>
            </b-table-lite>
          </div>
          <div class="circle-container">
            <v-chart class="chart" :option="option" />
          </div>
        </div>
      </transition>
    </div>
    <div>
      <select
        class="form-control"
        v-model="settings.selectedLocation"
        @change="fetchWidget"
      >
        <option :value="null">
          {{ $t("Widgets.WasteFractionWidget.AllLocations") }}
        </option>
        <option
          :value="location"
          v-for="location in locations"
          :key="location.id"
        >
          {{ location.name }}
        </option>
      </select>
    </div>
  </div>
  -->
</template>

<script>
import WidgetDatePicker from "@/views/dashboard/components/WidgetDatePicker.vue";
import { BSpinner, BTableLite, BRow, BCol } from "bootstrap-vue";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import WidgetService from "@/services/dashboard/widgets/widgetService-service";
import VChart from "vue-echarts";
import PieChart from "@/components/charts/PieChart.vue";

export default {
  components: {
    WidgetDatePicker,
    BSpinner,
    BTableLite,
    VChart,
    BCol,
    BRow,
    PieChart,
  },
  props: {
    settings: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      data: undefined,
      forcastData: undefined,
      tableFields: [
        {
          key: "category",
          label: this.$t("Generic.Category"),
        },
        {
          key: "share",
          label: this.$t("Generic.Share"),
        },
        {
          key: "tonsCO2",
          label: this.$t("Generic.TonsCO2"),
        },
        {
          key: "tonsCO2Simulated",
          label: this.$t("Generic.TonsCO2Simulated"),
        },
        {
          key: "saving",
          label: this.$t("Generic.PotentialSavings"),
        },
      ],
      noCatColor: "#ccc",
      reuseColor: "#438651",
      rezyclColor: "#3c7a49",
      combostionColor: "#305e38",
      specialColor: "#234328",
      landfillColor: "#16291a",
    };
  },
  async created() {
    await this.fetchWidget();
  },
  computed: {
    ...mapGetters({
      locations: "locationsOld/list",
    }),
    periodeFilterSetting() {
      if (
        this.settings.periodeFilter == undefined ||
        this.settings.periodeFilter.showLastTwelvMonths
      ) {
        let toDate = new Date();
        let fromDate = new Date();
        fromDate = fromDate.setFullYear(toDate.getFullYear() - 1);

        return {
          periode: [
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
          ],
          showLastTwelvMonths: true,
        };
      } else {
        return this.settings.periodeFilter;
      }
    },

    fromDate() {
      return this.periodeFilterSetting.periode[0];
    },

    toDate() {
      return this.periodeFilterSetting.periode[1];
    },

    fromDateDisplay() {
      return moment(this.fromDate).format("MMM DD, YYYY");
    },
    toDateDisplay() {
      return moment(this.toDate).format("MMM DD, YYYY");
    },
    tableContent() {
      return [
        {
          category: "WasteCategoryReuse",
          _cellVariants: { category: "reuse" },
          share: this.calcPercentageOf(
            this.data.reuseCO2,
            this.data.totalCO2
          ),
          tonsCO2: this.data.reuseCO2,
          tonsCO2Simulated: this.data.reuseCO2Simulated,
          saving: this.data.reuseCO2 - this.data.reuseCO2Simulated,
        },
        {
          category: "WasteCategoryRezycl",
          _cellVariants: { category: "rezycl" },
          share: this.$options.filters.toCurrency(
            this.calcPercentageOf(
              this.forcastData.rezyclCO2,
              this.forcastData.totalCO2
            )
          ),
          tonsCO2: this.data.rezyclCO2,
          tonsCO2Simulated: this.data.rezyclCO2Simulated,
          saving: this.data.rezyclCO2 - this.data.rezyclCO2Simulated,
        },
        {
          category: "WasteCategoryCombostion",
          _cellVariants: { category: "combostion" },
          share: this.$options.filters.toCurrency(
            this.calcPercentageOf(
              this.forcastData.combostionCO2,
              this.forcastData.totalCO2
            )
          ),
          tonsCO2: this.data.combostionCO2,
          tonsCO2Simulated: this.data.combostionCO2Simulated,
          saving: this.data.combostionCO2 - this.data.combostionCO2Simulated,
        },
        {
          category: "WasteCategorySpecial",
          _cellVariants: { category: "special" },
          share: this.$options.filters.toCurrency(
            this.calcPercentageOf(
              this.forcastData.specialCO2,
              this.forcastData.totalCO2
            )
          ),
          tonsCO2: this.data.specialCO2,
          tonsCO2Simulated: this.data.specialCO2Simulated,
          saving: this.data.specialCO2 - this.data.specialCO2Simulated,
        },
        {
          category: "WasteCategoryLandfill",
          _cellVariants: { category: "landfill" },
          share: this.$options.filters.toCurrency(
            this.calcPercentageOf(
              this.forcastData.landfillCO2,
              this.forcastData.totalCO2
            )
          ),
          tonsCO2: this.data.landfillCO2,
          tonsCO2Simulated: this.data.landfillCO2Simulated,
          saving: this.data.landfillCO2 - this.data.landfillCO2Simulated,
        },
      ];
    },

    currentTonsPriceCo2Title() {
      if (this.data == null) return this.$options.filters.toCurrency(0);

      return this.$options.filters.toCurrency(this.data.totalCO2);
    },

    SavingTonsPriceCo2Title() {
      if (this.data == null) return this.$options.filters.toCurrency(0);

      return this.$options.filters.toCurrency(
        this.data.totalCO2 - this.data.totalCO2Simulated
      );
    },

    currentTonsCo2Chart() {
      return [
        new this.getChartItem(
          this.data.reuseTons == 0 ? 0 : this.data.reuseCO2,
          this.$t("Generic.WasteCategoryReuse"),
          this.reuseColor,
          require("@/assets/images/widget/reusesvg.png")
        ),
        new this.getChartItem(
          this.data.rezyclCO2 == 0 ? 0 : this.data.rezyclCO2,
          this.$t("Generic.WasteCategoryRezycl"),
          this.rezyclColor,
          require("@/assets/images/widget/rezycl.png")
        ),
        new this.getChartItem(
          this.data.combostionTons == 0 ? 0 : this.data.combostionCO2,
          this.$t("Generic.WasteCategoryCombostion"),
          this.combostionColor,
          require("@/assets/images/widget/CombostionCategory.png")
        ),
        new this.getChartItem(
          this.data.specialTons == 0 ? 0 : this.data.specialCO2,
          this.$t("Generic.WasteCategorySpecial"),
          this.specialColor,
          require("@/assets/images/widget/SpecialCategory.png")
        ),
        new this.getChartItem(
          this.data.landfillTons == 0 ? 0 : this.data.landfillCO2,
          this.$t("Generic.WasteCategoryLandfill"),
          this.landfillColor,
          require("@/assets/images/widget/LandfillCategory.png")
        ),
      ];
    },

    savingTonsCo2Chart() {
      return [
        new this.getChartItem(
          this.data.reuseTons == 0 ? 0 : this.data.reuseCO2Simulated,
          this.$t("Generic.WasteCategoryReuse"),
          this.reuseColor,
          require("@/assets/images/widget/reusesvg.png")
        ),
        new this.getChartItem(
          this.data.rezyclCO2 == 0 ? 0 : this.data.rezyclCO2Simulated,
          this.$t("Generic.WasteCategoryRezycl"),
          this.rezyclColor,
          require("@/assets/images/widget/rezycl.png")
        ),
        new this.getChartItem(
          this.data.combostionTons == 0 ? 0 : this.data.combostionCO2Simulated,
          this.$t("Generic.WasteCategoryCombostion"),
          this.combostionColor,
          require("@/assets/images/widget/CombostionCategory.png")
        ),
        new this.getChartItem(
          this.data.specialTons == 0 ? 0 : this.data.specialCO2Simulated,
          this.$t("Generic.WasteCategorySpecial"),
          this.specialColor,
          require("@/assets/images/widget/SpecialCategory.png")
        ),
        new this.getChartItem(
          this.data.landfillTons == 0 ? 0 : this.data.landfillCO2Simulated,
          this.$t("Generic.WasteCategoryLandfill"),
          this.landfillColor,
          require("@/assets/images/widget/LandfillCategory.png")
        ),
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchLocations: "locationsOld/fetchList",
    }),
    getChartItem(value, name, color, background, labelformatter = "") {
      let obj = {
        value: value,
        name: name,
        itemStyle: { color: color },
        label: {
          formatter: labelformatter,
          backgroundColor: {
            image: value > 0 ? background : "transparent",
          },
          width: 25,
          height: 25,
        },
      };

      return obj;
    },
    async fetchWidget() {
      try {
        const service = new WidgetService();
        this.loading = true;
        let response = await service.getDistributionSimulationWidget(
          this.fromDate,
          this.toDate,
          this.settings.selectedLocation?.id,
          this.settings.wasteCategory
        );
        this.data = response.data.data;
        this.forcastData = JSON.parse(JSON.stringify(response.data.data));
        await this.fetchLocations({include: ["infoCards"]});
      } finally {
        this.loading = false;
      }
    },
    calcPercentageOf(input, total) {
      if (input == 0 || total == 0) return 0;
      return (input / total) * 100;
    },
    divedeOut(total, divider) {
      if (total == 0 || divider == 0) return 0;
      return total / divider;
    },
  },
  watch: {
    periodeFilterSetting(newValue, oldValue) {
      if (newValue != oldValue) this.fetchWidget();
    },
  },
};
</script>


<style lang="scss" scoped>
.widget-container {
  height: 100%;
  justify-content: space-between;
  padding: 10px;

  .topbar {
    > div {
      width: 50%;
    }
    .radios {
      > * {
        input {
          margin-right: 3px;
        }
        padding: 0 5px;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.show-pr-unit-box {
  background-color: #f3f2f7;
  padding: 10px;
}
</style>

<style lang="scss">
.circle-container {
  display: flex;
  width: 100%;
  height: 100%;
  > div {
    width: 30%;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.table-reuse {
  background: #438651;
  color: white;
}
.table-rezycl {
  background: #3c7a49;
  color: white;
}
.table-combostion {
  background: #305e38;
  color: white;
}
.table-special {
  background: #234328;
  color: white;
}
.table-landfill {
  background: #16291a;
  color: white;
}
</style>