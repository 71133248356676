<template>
  <div class="widget-container">
    <page-loader :loading="fetching">
      <div>
        <div class="header">
          <h1>
            {{ $t("Widgets.OrdersOverviewWidget.Headline") }}
          </h1>
          <div>
            <b-form-select v-model="settings.locationId" @change="fetchOrders">
              <b-form-select-option :value="undefined"
                >All locations</b-form-select-option
              >
              <b-form-select-option
                :value="location.id"
                v-for="location in locations"
                :key="location.id"
                >{{ location.name }}</b-form-select-option
              >
            </b-form-select>
          </div>
        </div>
        <b-table
          :fields="tableFields"
          :items="orders"
          class="orders-table"
          show-empty
          :empty-text="$t('Widgets.OrdersOverviewWidget.NoOrdersToShowText')"
        >
          <template #cell(ordere)="data">
            {{ data.item.orderer.name }}<br />
            <small>{{ format_date(data.item.orderDate) }}</small>
          </template>
          <template #cell(container)="data">
            <span class="font-weight-bold"
              >{{ data.item.orderItem.name }}
            </span>
            <small> {{ data.item.orderItem.refId }}</small
            ><br />
            <small
              >{{ data.item.pickupLocation.zip }}
              {{ data.item.pickupLocation.city }},
              {{ data.item.pickupLocation.address }} -
              {{ data.item.pickupLocation.name }}</small
            >
          </template>
          <template #cell(status)="data">
            <small>
              <span v-if="data.item.status == 'Created'">
                {{ $t("InfoCardOrderListTable.NotApproved") }}
              </span>
              <span v-else-if="data.item.status == 'Accepted'">
                {{ $t("InfoCardOrderListTable.Approved") }}<br />
                <small>{{ format_datetime(data.item.acceptedAt) }}</small>
              </span>
              <span v-else-if="data.item.status == 'Canceled'"
                >{{ $t("InfoCardOrderListTable.Canceled") }}}}<br />
                <small>{{ format_datetime(data.item.canceledAt) }}</small>
              </span>
              <span v-else>
                {{ $t("InfoCardOrderListTable.Emptied") }}<br />
                <small>{{ format_datetime(data.item.completedAt) }}</small>
              </span>
            </small>
          </template>
        </b-table>
        <div class="text-center font-weight-bold mt-1 mb-1 text-primary">
          <a
            href="#"
            v-if="pageInfo && pageInfo.nextPage > 0"
            @click.prevent="fetchNextPageOfOrders"
            >{{ $t("Widgets.OrdersOverviewWidget.FetchMoreOrders") }}
            <feather-icon icon="ChevronDownIcon"
          /></a>
        </div>
      </div>
    </page-loader>
  </div>
</template>

<script>
import {
  BTable,
  BOverlay,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import OrdersOverviewWidget from "@/services/dashboard/widgets/ordersOverviewWidget-service";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import { mapGetters } from "vuex";
export default {
  components: { BTable, BOverlay, PageLoader, BFormSelect, BFormSelectOption },
  props: ["settings"],
  async created() {
    await this.fetchOrders();
  },
  data() {
    return {
      fetching: false,
      pageInfo: undefined,
      orders: [],
      tableFields: [
        {
          key: "requisitionId",
          label: this.$t("Widgets.OrdersOverviewWidget.RequisitionId"),
        },
        {
          key: "ordere",
          label: this.$t("Widgets.OrdersOverviewWidget.Orderer"),
        },
        {
          key: "container",
          label: this.$t("Widgets.OrdersOverviewWidget.Container"),
        },
        {
          key: "status",
          label: this.$t("Widgets.OrdersOverviewWidget.Status"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ locations: "locations/list" }),
  },
  methods: {
    async fetchOrders() {
      this.fetching = true;
      const service = new OrdersOverviewWidget();
      var result = (await service.getOrders(1, 4, this.settings.locationId))
        .data;
      this.orders = result.data;
      this.pageInfo = result.paging;
      this.fetching = false;
    },
    async fetchNextPageOfOrders() {
      const service = new OrdersOverviewWidget();
      var result = (
        await service.getOrders(this.pageInfo.nextPage, this.pageInfo.perPage, this.settings.locationId)
      ).data;
      this.orders = [...this.orders, ...result.data];
      this.pageInfo = result.paging;
    },
  },
};
</script>
<style lang="scss" scoped>
.widget-container {
  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;

  .header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    select {
      width: 250px;
    }
  }

  .orders-table {
    border-bottom: 2px solid #ebe9f1;
  }
}
</style>