<template>
  <div class="totalsum-widget-container d-flex flex-column">
    <div class="topbar d-flex">
      <div>
        <div class="periode">{{ fromDateDisplay }} - {{ toDateDisplay }}</div>
      </div>
      <div class="d-flex justify-content-end">
        <widget-date-picker v-model="settings.periodeFilter" />
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center h-100"
      >
        <b-spinner />
      </div>
      <div
        class="content-container d-flex flex-column"
        v-else-if="settings.selectedCategory == 'CO2IncludingTransport'"
      >
        <small class="transportText"
          ><feather-icon icon="TruckIcon" class="icon"/>{{
            transportValue | toCurrency
          }}</small
        >
        <span class="result Economic">{{
          (value + transportValue) | toCurrency
        }}</span>
        <span class="result-text">{{
          selectedOptionText[settings.selectedCategory]
        }}</span>
      </div>
      <div class="content-container d-flex flex-column" v-else>
        <span class="result Economic">{{ value | toCurrency }}</span>
        <!-- <span v-else class="result">{{ value }}</span> -->
        <span class="result-text">{{
          selectedOptionText[settings.selectedCategory]
        }}</span>
      </div>
    </transition>
    <select
      @change="fetchWidget"
      class="form-control"
      v-model="settings.selectedCategory"
    >
      <option value="CO2">
        {{ $t("Widgets.TotalSumWidget.Categories.CO2") }}
      </option>
      <option value="CO2IncludingTransport">
        {{ $t("Widgets.TotalSumWidget.Categories.CO2IncludingTransport") }}
      </option>
      <option value="Economic">
        {{ $t("Widgets.TotalSumWidget.Categories.Economic") }}
      </option>
      <option value="TimeConsumption">
        {{ $t("Widgets.TotalSumWidget.Categories.TimeConsumption") }}
      </option>
      <option value="WasteCollenction">
        {{ $t("Widgets.TotalSumWidget.Categories.WasteCollenction") }}
      </option>
      <option value="TonsWasteCollected">
        {{ $t("Widgets.TotalSumWidget.Categories.TonsWasteCollected") }}
      </option>
      <option value="CubicWasteCollected">
        {{ $t("Widgets.TotalSumWidget.Categories.CubicWasteCollected") }}
      </option>
      <option value="TransportDistance">
        {{ $t("Widgets.TotalSumWidget.Categories.TransportDistance") }}
      </option>
    </select>
  </div>
</template>

<script>
import WidgetDatePicker from "@/views/dashboard/components/WidgetDatePicker.vue";
import WidgetService from "@/services/dashboard/widgets/widgetService-service";
import moment from "moment";
import { BSpinner } from "bootstrap-vue";

export default {
  props: {
    settings: {
      required: true,
    },
  },
  components: {
    WidgetDatePicker,
    BSpinner,
  },
  async created() {
    this.services = new WidgetService();
    await this.fetchWidget();
  },
  data() {
    return {
      dateRange: [],
      loading: false,
      services: null,
      selectedOptionText: {
        CO2: this.$t("Widgets.TotalSumWidget.SelectedCategoryText.CO2"),
        Economic: this.$t(
          "Widgets.TotalSumWidget.SelectedCategoryText.Economic"
        ),
        TimeConsumption: this.$t(
          "Widgets.TotalSumWidget.SelectedCategoryText.TimeConsumption"
        ),
        WasteCollenction: this.$t(
          "Widgets.TotalSumWidget.SelectedCategoryText.WasteCollenction"
        ),
        TonsWasteCollected: this.$t(
          "Widgets.TotalSumWidget.SelectedCategoryText.TonsWasteCollected"
        ),
        CubicWasteCollected: this.$t(
          "Widgets.TotalSumWidget.SelectedCategoryText.CubicWasteCollected"
        ),
        TransportDistance: this.$t(
          "Widgets.TotalSumWidget.SelectedCategoryText.TransportDistance"
        ),
        CO2IncludingTransport: this.$t(
          "Widgets.TotalSumWidget.SelectedCategoryText.CO2IncludingTransport"
        ),
      },
      value: 0,
      transportValue: 0,
    };
  },
  computed: {
    periodeFilterSetting() {
      if (
        this.settings.periodeFilter == undefined ||
        this.settings.periodeFilter.showLastTwelvMonths
      ) {
        let toDate = new Date();
        let fromDate = new Date();
        fromDate = fromDate.setFullYear(toDate.getFullYear() - 1);

        return {
          periode: [
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
          ],
          showLastTwelvMonths: true,
        };
      } else {
        return this.settings.periodeFilter;
      }
    },

    fromDate() {
      return this.periodeFilterSetting.periode[0];
    },

    toDate() {
      return this.periodeFilterSetting.periode[1];
    },

    fromDateDisplay() {
      return moment(this.fromDate).format("MMM DD, YYYY");
    },
    toDateDisplay() {
      return moment(this.toDate).format("MMM DD, YYYY");
    },
  },
  methods: {
    fromDateUpdate(date) {
      this.fromDate = date;
      this.fetchWidget();
    },
    toDateUpdate(date) {
      this.toDate = date;
      this.fetchWidget();
    },
    async fetchWidget() {
      if (!this.fromDate || !this.toDate) return;
      try {
        this.loading = true;
        let resonse = await this.services.getData(
          "/totalsum/" + this.settings.selectedCategory,
          this.fromDate,
          this.toDate
        );
        this.value = resonse.amount;
        if (resonse.transportAmount)
          this.transportValue = resonse.transportAmount;
        else this.transportValue = 0;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    periodeFilterSetting() {
      this.fetchWidget();
    },
  },
};
</script>

<style lang="scss" scoped>
.totalsum-widget-container {
  height: 100%;
  justify-content: space-between;
  padding: 10px;
  .content-container {
    height: 100%;
    text-align: center;
    justify-content: center;
    .result {
      font-size: 6rem;
      font-weight: bold;
      color: #407e3d;
      line-height: 6rem;
      &.Economic {
        font-size: 3rem;
        line-height: 4rem;
      }
    }
    .result-text {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
  .topbar {
    > div {
      width: 50%;
    }
    .radios {
      > * {
        input {
          margin-right: 3px;
        }
        padding: 0 5px;
      }
    }
  }

  .transportText {
    font-size: 1.5rem;
    font-weight: bold;
    color: #407e3d;

    .icon{
      margin-right: 5px;
    }
  }
}
</style>