<template>
  <dragablemodal
    :name="'widgetPicker'"
    ref="dragablemodal"
    :height="'auto'"
    :width="600"
  >
    <header>{{ $t("Generic.Choose") }}</header>
    <div class="modals-content">
      <ul>
        <li
          v-for="(widgettype, index) in widgettypes"
          :key="index"
          :class="{
            'bg-primary text-white': selected == widgettype.settings.type,
          }"
          @click="selected = widgettype.settings.type"
        >
          {{ $t("WidgetTypes." + widgettype.name) }}
        </li>
      </ul>
    </div>
    <footer class="modal-footer">
      <div class="w-100">
        <b-button variant="primary" class="float-right" @click="handleOk">
          {{ $t("Generic.Add") }}
        </b-button>
        <b-button variant="light" class="float-right mr-2" @click="hide">
          {{ $t("Generic.Cancel") }}
        </b-button>
      </div>
    </footer>
  </dragablemodal>
</template>

<script>
import dragablemodal from "@/components/layout/dragablemodal.vue";

import { BModal, BCardText, BCard, BButton } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BCardText,
    BCard,
    BButton,
    dragablemodal,
  },
  data() {
    return {
      widgettypes: [
        {
          type: "TOTALSUM_WIDGET",
          name: "TotalWidget",
          width: 2,
          height: 3,
          minHeight: 2,
          minWidth: 2,
          settings: {
            type: "TOTALSUM_WIDGET",
            name: "TotalWidget",
            selectedCategory: "CO2",
          },
        },
        {
          type: "WASTEFRACTION_WIDGET",
          name: "WasteFraction",
          width: 5,
          height: 3,
          minHeight: 2,
          minWidth: 3,
          settings: {
            type: "WASTEFRACTION_WIDGET",
            name: "WasteFraction",
            selectedLocation: null,
            selectedDataType: "ton",
          },
        },
        {
          type: "WASTE_DISTRIBUTION_PYRAMID",
          name: "DistributionOverview",
          width: 6,
          minWidth: 6,
          minHeight: 3,
          height: 3,
          settings: {
            type: "WASTE_DISTRIBUTION_PYRAMID",
            name: "DistributionOverview",
          },
        },
        {
          type: "WASTE_DISTRIBUTION_CIRCLE",
          name: "CircleDistributionOverview",
          width: 4,
          minWidth: 4,
          minHeight: 3,
          height: 3,
          settings: {
            type: "WASTE_DISTRIBUTION_CIRCLE",
            name: "CircleDistributionOverview",
          },
        },
        {
          type: "FINANCIAL_OVERVIEW_WIDGET",
          name: "FinancialOverviewWidget",
          width: 4,
          minWidth: 4,
          minHeight: 3,
          height: 3,
          settings: {
            type: "FINANCIAL_OVERVIEW_WIDGET",
            name: "FinancialOverviewWidget",
          },
        },
        {
          tyep: "ORDERS_OVERVIEW_WIDGET",
          name: "OrderOverviewWidget",
          width: 6,
          minWidth: 5,
          minHeight: 3,
          height: 3,
          settings: {
            type: "ORDERS_OVERVIEW_WIDGET",
            name: "OrderOverviewWidget",
          },
        },
        {
          tyep: "WASTE_DISTRIBUTION_SIMULATOR_WIDGET",
          name: "WasteDistributionSimulatorWidget",
          width: 9,
          minWidth: 9,
          minHeight: 4,
          height: 4,
          settings: {
            type: "WASTE_DISTRIBUTION_SIMULATOR_WIDGET",
            name: "WasteDistributionSimulatorWidget",
            selectedLocation: null,
            wasteCategory: "Combostion",
          },
        },
        {
          type: "POSTINGS_OVERVIEW_WIDGET",
          name: "postingsOverviewWidget",
          width: 3,
          minWidth: 2,
          minHeight: 2,
          height: 2,
          settings: {
            type: "POSTINGS_OVERVIEW_WIDGET",
            name: "postingsOverviewWidget",
            selectedLocation: null,
            wasteCategory: "Combostion",
            infocards: [],
            viewMode: "TABLE",
          },
        },
        {
          type: "FRACTION_PRICE_HISTORY_WIDGET",
          name: "fractionPriceHistoryWidget",
          width: 5,
          minWidth: 3,
          minHeight: 3,
          height: 3,
          settings: {
            type: "FRACTION_PRICE_HISTORY_WIDGET",
            name: "fractionPriceHistoryWidget",
          },
        },

        /*
        {
          type: "LOCATIONOVERVIEW_WIDGET",
          name: "LocationOverview",
          layouts: [
            {
              layout: "lg",
              width: 10,
              maxWidth: 10,
              height: 3,
            },
          ],

          width: 6,
          maxWidth: 10,
          height: 3,

          settings: {
            type: "LOCATIONOVERVIEW_WIDGET",
            name: "LocationOverview",
          },
        },
        */
      ],
      selected: null,
    };
  },
  methods: {
    open() {
      this.$refs.dragablemodal.show();
    },
    handleOk() {
      const selectedWidget = this.widgettypes.find(
        (x) => x.settings.type === this.selected
      );
      this.$emit("widget-picked", JSON.parse(JSON.stringify(selectedWidget)));
      this.hide();
    },
    hide() {
      this.selected = null;
      this.$refs.dragablemodal.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    border-radius: 8px;

    &.selected {
      background: linear-gradient(118deg, #30cf84, rgba(48, 207, 132, 0.7));
      color: #fff;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
</style>