<template>
  <div v-if="account && account.type == 'Directory' && role != 'SuperAdmin'">
    <h1>
      {{ $t("Generic.UserHasNoConcern") }}
    </h1>
  </div>
  <div v-else>
    <widget-picker ref="widgetPicker" @widget-picked="handleAddWidget" />
    <!--
    <b-button @click="showWidgetModal" variant="primary">
      {{ $t("HomeView.WidgetBtn") }}
    </b-button>
    -->
    <dashboard :id="'dashExample'" v-if="widgetLayouts.length > 0">
      <dash-layout
        v-for="layout in widgetLayouts"
        v-bind="layout"
        :debug="false"
        :key="layout.breakpoint"
      >
        <dash-item
          v-for="item in layout.items"
          v-bind.sync="item"
          :key="item.id"
        >
          <b-card no-body style="height: 100%" class="widget-con">
            <button
              type="button"
              aria-label="Close"
              class="close"
              @click="removeWidget(item.id)"
            >
              ×
            </button>
            <div class="inner">
              <waste-distribution-pyramid-widget
                v-if="item.settings.type === 'WASTE_DISTRIBUTION_PYRAMID'"
                :settings="item.settings"
              />
              <waste-fraction-widget
                v-if="item.settings.type === 'WASTEFRACTION_WIDGET'"
                :settings="item.settings"
              />
              <totalsum-widget
                v-if="item.settings.type === 'TOTALSUM_WIDGET'"
                :settings="item.settings"
              />
              <location-overview-widget
                v-if="item.settings.type === 'LOCATIONOVERVIEW_WIDGET'"
              />
              <waste-distribution-circle-widget
                v-if="item.settings.type === 'WASTE_DISTRIBUTION_CIRCLE'"
                :settings="item.settings"
              />
              <finanical-overview-widget
                v-if="item.settings.type === 'FINANCIAL_OVERVIEW_WIDGET'"
                :settings="item.settings"
              />
              <orders-overview-widget
                v-if="item.settings.type === 'ORDERS_OVERVIEW_WIDGET'"
                :settings="item.settings"
              />

              <waste-distribution-simulator-widget
                v-if="
                  item.settings.type == 'WASTE_DISTRIBUTION_SIMULATOR_WIDGET'
                "
                :settings="item.settings"
              />
              <postings-overview-widget
                v-if="item.settings.type == 'POSTINGS_OVERVIEW_WIDGET'"
                :settings="item.settings"
              />
              <fraction-price-history-widget
                v-if="item.settings.type == 'FRACTION_PRICE_HISTORY_WIDGET'"
                :settings="item.settings"
              />
            </div>
          </b-card>
        </dash-item>
      </dash-layout>
    </dashboard>
  </div>
</template>

<script>
import { BModal, BCardText, BCard, BButton } from "bootstrap-vue";
import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";
import WasteDistributionPyramidWidget from "../dashboard/widgets/WasteDistributionPyramidWidget.vue";
import WasteDistributionCircleWidget from "../dashboard/widgets/WasteDistributionCircleWidget.vue";
import WidgetPicker from "../dashboard/components/WidgetPicker.vue";
import { uuid } from "vue-uuid";
import DashboardLayoutService from "@/services/dashboard/layout-service";
import { mapActions, mapGetters } from "vuex";
import authHelper from "@/auth";
import WasteFractionWidget from "@/views/dashboard/widgets/WasteFractionWidget.vue";
import TotalsumWidget from "@/views/dashboard/widgets/TotalSumWidget.vue";
import LocationOverviewWidget from "@/views/dashboard/widgets/LocationOverviewWidget.vue";
import FinanicalOverviewWidget from "@/views/dashboard/widgets/FinanicalOverviewWidget.vue";
import OrdersOverviewWidget from "./widgets/OrdersOverviewWidget.vue";
import WasteDistributionSimulatorWidget from "./widgets/WasteDistributionSimulatorWidget.vue";
import PostingsOverviewWidget from "./widgets/PostingsOverviewWidget.vue";
import FractionPriceHistoryWidget from "./widgets/FractionPriceHistoryWidget.vue";

export default {
  components: {
    Dashboard,
    DashLayout,
    DashItem,
    BModal,
    BCardText,
    BCard,
    BButton,
    WasteDistributionPyramidWidget,
    WasteDistributionCircleWidget,
    FinanicalOverviewWidget,
    WidgetPicker,
    WasteFractionWidget,
    TotalsumWidget,
    LocationOverviewWidget,
    OrdersOverviewWidget,
    WasteDistributionSimulatorWidget,
    PostingsOverviewWidget,
    FractionPriceHistoryWidget,
  },
  async mounted() {
    if (this.account && this.account.type != "Directory") {
      this.$router.push({ name: "dashboards.home" });
      return;
    }

    if (this.account && this.account.type == "Directory") {
      this.$router.push({ name: "directory.accounts.index" });
      return;
    }

    // Check if user has new dashboard
    await this.fetchDashboards();
    if (this.dashboards.length > 0) {
      this.$router.push({ name: "dashboards.home" });
      return;
    }
    // Old behaviour
    this.role = authHelper.getRole();
    this.widgetLayoutsLoading = true;
    let layoutService = new DashboardLayoutService();
    let layoutObj = (await layoutService.getLayout())?.layout;

    if (this.account.type == "Directory") {
      this.$router.push({ name: "directory.accounts.index" });
      return;
    }
    if (
      (this.account && this.account.type != "Directory" && layoutObj == null) ||
      layoutObj.length == 0
    )
    {
      this.$router.push({ name: "dashboards.home" });
      return;
    }

    if (layoutObj != null) this.widgetLayouts[0].items = layoutObj;
    this.widgetLayoutsLoading = false;
  },
  async created() {},
  methods: {
    ...mapActions({
      fetchDashboards: "user/dashboards/fetchList",
    }),
    showWidgetModal() {
      this.$refs.widgetPicker.open();
    },
    handleAddWidget(widget) {
      widget.x = 0;
      widget.y = this.nextYval;
      widget.id = uuid.v4();
      this.widgetLayouts[0].items.push(widget);

      /*
      let lay = this.dlayouts.find((i) => i.breakpoint == "lg");
      lay.items.push({ x: 0, y: 0, width: 1, height: 1 });
      */
    },
    removeWidget(widgetId) {
      const indexToRemove = this.widgetList.indexOf(
        this.widgetList.find((x) => x.id == widgetId)
      );
      this.widgetList.splice(indexToRemove, 1);
    },
  },
  computed: {
    ...mapGetters({
      account: "appUser/getCurrentAccountScope",
      dashboards: "user/dashboards/list",
    }),
    widgetList() {
      return this.widgetLayouts[0].items;
    },
    nextYval() {
      const widgetWithHeighestX = this.widgetWithHeighestYVal;
      if (widgetWithHeighestX == null) return 0;
      return widgetWithHeighestX.x + widgetWithHeighestX.height;
    },
    widgetWithHeighestXVal() {
      if (this.widgetList.length == 0) return null;
      return this.widgetList.reduce((prev, cur) =>
        prev.x > cur.x ? prev : cur
      );
    },
    widgetWithHeighestYVal() {
      if (this.widgetList.length == 0) return null;
      return this.widgetList.reduce((prev, cur) =>
        prev.y > cur.y ? prev : cur
      );
    },
  },
  data() {
    return {
      role: "",
      widgetLayoutsLoading: false,
      widgetLayouts: [
        {
          breakpoint: "lg",
          breakpointWidth: 1200,
          numberOfCols: 10,
          items: [],
        },
      ],
    };
  },
  watch: {
    widgetList: {
      async handler(newValue) {
        if (this.widgetLayoutsLoading) return;
        let layoutService = new DashboardLayoutService();
        await layoutService.saveLayout(newValue);
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.r {
  height: 100%;
  width: 100%;
  border: 2px solid #42b983;
  border-radius: 5px;
}

.widget-con {
  .inner {
    height: 100%;
    /* overflow: hidden; */
  }
  .close {
    padding: 0.2rem 0.62rem;
    box-shadow: 0 5px 20px 0 rgb(34 41 47 / 10%);
    border-radius: 0.357rem;
    background: #fff;
    margin: -0.8rem -1.4rem -0.8rem auto;
    opacity: 1;
    z-index: 99999;
    visibility: hidden;
  }
  &:hover {
    .close {
      visibility: visible;
    }
  }
}
</style>