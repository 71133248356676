<template>
  <div class="widget-container d-flex flex-column">
    <div class="topbar d-flex flex-column">
      <div class="d-flex justify-content-between">
        <div>
          <div class="periode">{{ fromDateDisplay }} - {{ toDateDisplay }}</div>
        </div>
        <div class="d-flex justify-content-end">
          <widget-date-picker v-model="settings.periodeFilter" />
        </div>
      </div>
      <div class="d-flex justify-content-between mt-1">
        <div class="radios d-flex">
          <label class="d-flex">
            <input
              @change="fetchWidget()"
              :name="`${widgetId}_type`"
              type="radio"
              value="Income"
              v-model="settings.postingCategory"
            />
            {{ $t("Generic.Income") }}
          </label>
          <label class="d-flex">
            <input
              @change="fetchWidget()"
              :name="`${widgetId}_type`"
              type="radio"
              value="Charge"
              v-model="settings.postingCategory"
            />
            {{ $t("Generic.Charge") }}
          </label>
        </div>
        <div class="radios d-flex">
          <label class="d-flex">
            <input
              :name="`${widgetId}_timeSpan`"
              type="radio"
              value="Monthly"
              v-model="settings.timespanView"
            />
            {{ $t("Widgets.FractionPriceHistoryWidget.TimeSpanMonthly") }}
          </label>
          <label class="d-flex">
            <input
              :name="`${widgetId}_timeSpan`"
              type="radio"
              value="Yearly"
              v-model="settings.timespanView"
            />
            {{ $t("Widgets.FractionPriceHistoryWidget.TimeSpanYearly") }}
          </label>
        </div>
      </div>
    </div>
    <div class="h-100">
      <page-loader :loading="loading">
        <div
          v-if="
            !settings.locations ||
            !settings.wastefractions ||
            settings.locations.length == 0 ||
            settings.wastefractions.length == 0
          "
          class="h-100 d-flex align-items-center justify-content-center"
        >
          <h3 class="text-muted text-center">
            {{ $t("Widgets.FractionPriceHistoryWidget.StartupText") }}
          </h3>
        </div>
        <div class="content-wrap h-100" v-else-if="!loading">
          <line-chart :options="chartOptions" />
        </div>
      </page-loader>
    </div>
    <div class="d-flex justify-content-between">
      <div>
        <treeselect
          v-if="!loading"
          class="mr-1"
          :multiple="true"
          :options="locationOptions"
          :value-consists-of="'LEAF_PRIORITY'"
          :placeholder="
            $t('Widgets.FractionPriceHistoryWidget.ChooseLocations')
          "
          v-model="settings.locations"
          @input="updateWidget()"
        />
      </div>
      <div>
        <treeselect
          v-if="!loading"
          class="ml-1"
          :multiple="false"
          :options="wastefractionOptions"
          :value-consists-of="'LEAF_PRIORITY'"
          :placeholder="
            $t('Widgets.FractionPriceHistoryWidget.ChooseWastefractions')
          "
          v-model="settings.wastefractions"
          @input="updateWidget()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import WidgetDatePicker from "../components/WidgetDatePicker.vue";
import WidgetService from "@/services/dashboard/widgets/widgetService-service";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import { uuid } from "vue-uuid";
import LineChart from "@/components/charts/LineChart.vue";

export default {
  components: { WidgetDatePicker, Treeselect, PageLoader, LineChart },
  props: {
    settings: {
      required: true,
    },
  },
  async created() {
    if (!this.settings.postingCategory)
      this.settings.postingCategory = "Income";
    if (!this.settings.timespanView) this.settings.timespanView = "Monthly";
    await this.fetchWidget();
  },
  data() {
    return {
      widgetId: uuid.v4(),
      widgetData: null,
      loading: false,
      chartGrid: {
        left: 30,
        right: 30,
        containLabel: true,
      },
    };
  },
  computed: {
    periodeFilterSetting() {
      if (
        this.settings.periodeFilter == undefined ||
        this.settings.periodeFilter.showLastTwelvMonths
      ) {
        let toDate = new Date();
        let fromDate = new Date();
        fromDate = fromDate.setFullYear(toDate.getFullYear() - 1);

        return {
          periode: [
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
          ],
          showLastTwelvMonths: true,
        };
      } else {
        return this.settings.periodeFilter;
      }
    },

    fromDate() {
      return this.periodeFilterSetting.periode[0];
    },

    toDate() {
      return this.periodeFilterSetting.periode[1];
    },

    fromDateDisplay() {
      return moment(this.fromDate).format("MMM DD, YYYY");
    },
    toDateDisplay() {
      return moment(this.toDate).format("MMM DD, YYYY");
    },
    locationOptions() {
      if (this.widgetData == null) return [];
      return this.widgetData.locations.map((location) => {
        return {
          id: location.id,
          label: location.name,
        };
      });
    },
    wastefractionOptions() {
      if (this.widgetData == null) return [];
      return this.widgetData.fractions.map((fraction) => {
        return {
          id: fraction.id,
          label: fraction.name,
        };
      });
    },
    chartOptions() {
      return {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          valueFormatter: (value) => this.$options.filters.toCurrency(value),
        },
        legend: {
          data: !this.widgetData
            ? []
            : this.widgetData.locationRecords.map((item) => item.name),
        },
        grid: {
          left: "20",
          right: "30",
          bottom: "10",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chartHeader,
        },
        yAxis: {
          type: "value",
        },
        series: this.chartData,
      };
    },

    chartLegend() {
      if (this.widgetData == null) return [];
      return this.widgetData.locationRecords.map((item) => item.name);
    },
    chartHeader() {
      if (!this.widgetData) return [];
      if (this.settings.timespanView == "Monthly")
        return this.widgetData.locationRecords[0].wasteFractionEntries[0].statEntries.map(
          (item) => {
            const date = new Date(item.year, item.month, 1);
            return `${date.toLocaleString("default", { month: "short" })} - ${
              item.year
            }`;
          }
        );
      else
        return this.widgetData.locationRecords[0].wasteFractionEntries[0].statEntries
          .map((item) => `${item.year}`)
          .filter((value, index, self) => self.indexOf(value) === index);
    },
    chartData() {
      if (!this.widgetData) return [];
      return this.widgetData.locationRecords.map((item) => {
        return {
          name: item.name,
          type: "line",
          data: this.getStatEntries(item.wasteFractionEntries[0]),
        };
      });
    },
  },
  methods: {
    async fetchWidget() {
      try {
        this.loading = true;
        const service = new WidgetService();
        var result = await service.getFractionPriceHistoryWidget(
          this.fromDate,
          this.toDate,
          !this.settings.locations ? [] : this.settings.locations,
          !this.settings.wastefractions ? [] : this.settings.wastefractions,
          this.settings.postingCategory
        );
        this.widgetData = result.data.data;
      } finally {
        this.loading = false;
      }
    },
    async updateWidget() {
      if (
        !this.settings.locations ||
        this.settings.locations.length == 0 ||
        !this.settings.wastefractions ||
        this.settings.wastefractions.length == 0
      )
        return;
      await this.fetchWidget();
    },
    getStatEntries(fractionEntry) {
      if (this.settings.timespanView == "Monthly") {
        return fractionEntry.statEntries.map((item) => item.amount);
      } else {
        return Object.values(
          fractionEntry.statEntries.reduce(function (rv, x) {
            let existingValue = rv[x["year"]] || 0;
            rv[x["year"]] = existingValue += x.amount;
            return rv;
          }, {})
        );
      }
    },
  },
  watch: {
    periodeFilterSetting(newValue, oldValue) {
      if (newValue != oldValue) this.updateWidget();
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-container {
  height: 100%;
  justify-content: space-between;
  padding: 10px;

  .content-wrap {
    overflow: hidden;
  }

  .topbar {
    .radios {
      > * {
        input {
          margin-right: 3px;
        }
        padding: 0 5px;
      }
    }
  }
}
</style>