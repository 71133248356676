<template>
  <div class="widget-container d-flex flex-column">
    <div class="topbar d-flex">
      <div>
        <div class="periode">{{ fromDateDisplay }} - {{ toDateDisplay }}</div>
        <h1 class="header">
          {{
            selectedLocation == null
              ? $t("Widgets.WasteFractionWidget.AllLocations")
              : selectedLocation.name
          }}
        </h1>
        <div class="radios d-flex">
          <label class="d-flex">
            <input
              @change="fetchWidget()"
              :name="`${widgetId}_type`"
              type="radio"
              value="ton"
              v-model="settings.selectedDataType"
            />
            {{ $t("Widgets.WasteFractionWidget.Tons") }}
          </label>
          <label class="d-flex">
            <input
              @change="fetchWidget()"
              :name="`${widgetId}_type`"
              type="radio"
              value="cubic"
              v-model="settings.selectedDataType"
            />
            {{ $t("Widgets.WasteFractionWidget.Cubic") }}
          </label>
          <label class="d-flex">
            <input
              @change="fetchWidget()"
              :name="`${widgetId}_type`"
              type="radio"
              value="co2"
              v-model="settings.selectedDataType"
            />
            {{ $t("Widgets.WasteFractionWidget.CO2") }}
          </label>
          <label class="d-flex">
            <input
              @change="fetchWidget()"
              :name="`${widgetId}_type`"
              type="radio"
              value="co2IncludingTransport"
              v-model="settings.selectedDataType"
            />
            {{ $t("Widgets.WasteFractionWidget.co2IncludingTransport") }}
          </label>
          <label class="d-flex">
            <input
              @change="fetchWidget()"
              :name="`${widgetId}_type`"
              type="radio"
              value="economy"
              v-model="settings.selectedDataType"
            />
            {{ $t("Widgets.WasteFractionWidget.Economy") }}
          </label>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <widget-date-picker v-model="settings.periodeFilter" />
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center h-100"
      >
        <b-spinner />
      </div>
      <div
        class="d-flex justify-content-center align-items-center h-100"
        v-else-if="values.length == 0"
      >
        <h3 class="font-weight-light">
          {{ $t("Widgets.WasteFractionWidget.NoData") }}
        </h3>
      </div>
      <div class="bar-container d-flex" v-else>
        <bar-chart
          :grid="chartGrid"
          :axisLabel="axisLabel"
          :chartHeaders="chartHeaders"
          :chartSeries="chartValues"
        ></bar-chart>
      </div>
    </transition>
    <select
      @change="fetchWidget"
      class="form-control"
      v-model="settings.selectedLocation"
    >
      <option :value="null">
        {{ $t("Widgets.WasteFractionWidget.AllLocations") }}
      </option>
      <option
        :value="location"
        v-for="location in locations"
        :key="location.id"
      >
        {{ location.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import WidgetDatePicker from "@/views/dashboard/components/WidgetDatePicker.vue";
import WidgetService from "@/services/dashboard/widgets/widgetService-service";
import moment from "moment";
import { BSpinner, VBTooltip } from "bootstrap-vue";
import BarChart from "@/components/charts/BarChart.vue";
import { uuid } from 'vue-uuid';
export default {
  props: {
    settings: {
      required: true,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    WidgetDatePicker,
    BSpinner,
    VBTooltip,
    BarChart,
  },
  async created() {
    this.services = new WidgetService();
    this.fetchLocations({include: ["infoCards"]});
    await this.fetchWidget();
  },
  data() {
    return {
      widgetId: uuid.v4(),
      loading: false,
      dataType: "ton",
      selectedLocation: null,
      services: null,
      values: [],
      dataTypeDisplay: {
        ton: this.$t("Widgets.WasteFractionWidget.Tons"),
        co2: this.$t("Widgets.WasteFractionWidget.Tons"),
        economy: this.$t("Widgets.WasteFractionWidget.Kr"),
        cubic: this.$t("Widgets.WasteFractionWidget.Cubic"),
      },
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 55,
        overflow: "truncate",
        width: "60",
      },
      chartGrid: {
        bottom: 80,
        left: 0,
        top: 0,
        right: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      locations: "locationsOld/list",
    }),

    chartHeaders() {
      if (this.values == undefined) return [];

      return this.values.map((a) => a.name);
    },
    chartValues() {
      const self = this;
      let series = [
        {
          type: "bar",
          stack: "total",
          itemStyle: {
            color: "#407e3d",
            borderRadius:
              this.settings.selectedDataType == "co2IncludingTransport"
                ? [0, 0, 0, 0]
                : [5, 5, 0, 0],
          },
          label: {
            show: true,
            color: "#383838",
            textBorderColor: "#333333",
            textBorderWidth: 0.1,
            fontSize: 13,
            fontWeight: "bold",
            position: "insideBottom",
            distance: 15,
            align: "left",
            verticalAlign: "middle",
            rotate: 90,
            formatter: function (params) {
              return self.$options.filters.toCurrency(params.data);
            },
          },
          data: [],
        },
      ];

      if (this.values != undefined) {
        series[0].data = this.values.map((a) => a.amount);
      }
      if (this.settings.selectedDataType == "co2IncludingTransport") {
        series.push({
          name: "Transport",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          itemStyle: {
            color: "#dddddd",
            borderRadius: [5, 5, 0, 0],
          },
          emphasis: {
            focus: "series",
          },
          label: {
            show: true,
            color: "#383838",
            textBorderColor: "#333333",
            textBorderWidth: 0.1,
            fontSize: 13,
            fontWeight: "bold",
            position: "insideBottom",
            distance: 15,
            align: "left",
            verticalAlign: "middle",
            rotate: 90,
            formatter: function (params) {
              return self.$options.filters.toCurrency(params.data);
            },
          },
          data: this.values.map((a) => a.transportAmount),
        });
      }
      return series;
    },
    periodeFilterSetting() {
      if (
        this.settings.periodeFilter == undefined ||
        this.settings.periodeFilter.showLastTwelvMonths
      ) {
        let toDate = new Date();
        let fromDate = new Date();
        fromDate = fromDate.setFullYear(toDate.getFullYear() - 1);

        return {
          periode: [
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
          ],
          showLastTwelvMonths: true,
        };
      } else {
        return this.settings.periodeFilter;
      }
    },

    fromDate() {
      return this.periodeFilterSetting.periode[0];
    },

    toDate() {
      return this.periodeFilterSetting.periode[1];
    },

    fromDateDisplay() {
      return moment(this.fromDate).format("MMM DD, YYYY");
    },
    toDateDisplay() {
      return moment(this.toDate).format("MMM DD, YYYY");
    },
    max() {
      const max = this.values.reduce(function (prev, current) {
        return prev.amount > current.amount ? prev : current;
      });

      return max.amount;
    },
  },
  methods: {
    ...mapActions({
      fetchLocations: "locationsOld/fetchList",
    }),
    fromDateUpdate(date) {
      this.fromDate = date;
      this.fetchWidget();
    },
    toDateUpdate(date) {
      this.toDate = date;
      this.fetchWidget();
    },
    async fetchWidget() {
      if (!this.fromDate || !this.toDate) return;
      try {
        this.loading = true;
        let location = null;
        if (this.settings.selectedLocation != null) {
          location = this.settings.selectedLocation.id;
        }
        let resonse = await this.services.getDataWithLocation(
          "/wastefraction/" + this.settings.selectedDataType,
          location,
          this.fromDate,
          this.toDate
        );
        this.values = resonse;
      } finally {
        this.loading = false;
      }
    },
    getHeight(value) {
      if (value == 0 || !this.max) return 1 + "%";
      let p = value / this.max;
      let result = Math.round(p * 100);

      return result + "%";
    },
  },
  watch: {
    periodeFilterSetting() {
      this.fetchWidget();
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-container {
  height: 100%;
  justify-content: space-between;
  padding: 10px;
  .topbar {
    > div:first-child {
      width: 70%;
    }
    > div:last-child {
      width: 30%;
    }
    .radios {
      > * {
        input {
          margin-right: 3px;
        }
        padding: 0 5px;
      }
    }
  }

  .bar-container {
    height: 100%;
    width: 100%;
  }
}
</style>