import BaseService from "@/services/base/base.service";

export default class OrdersOverviewWidget extends BaseService {
    constructor() {
        super('widgets/orders-overview')
    }

    async getOrders(page, perPage, locationIdFilter = undefined) {
        return await this.get("", { page: page, perPage: perPage, locationId: locationIdFilter });
    }
}