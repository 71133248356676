<template>
  <div class="widget-container d-flex flex-column">
    <div class="topbar d-flex">
      <div>
        <div class="periode">{{ fromDateDisplay }} - {{ toDateDisplay }}</div>
        <h1 class="header">
          {{ $t("Widgets.FinancialOverviewWidget.Headline") }}
        </h1>
      </div>
      <div class="d-flex justify-content-end">
        <widget-date-picker v-model="settings.periodeFilter" />
      </div>
    </div>

    <div class="bar-container d-flex">
      <bar-chart
        :grid="chartGrid"
        :chartHeaders="chartHeader"
        :chartSeries="chartData"
      ></bar-chart>
    </div>
    <select
      @change="fetchWidget"
      class="form-control"
      v-model="settings.selectedLocation"
    >
      <option :value="undefined">
        {{ $t("Widgets.WasteFractionWidget.AllLocations") }}
      </option>
      <option
        :value="location"
        v-for="location in locations"
        :key="location.id"
      >
        {{ location.name }}
      </option>
    </select>
  </div>
</template>

<script>
import WidgetDatePicker from "@/views/dashboard/components/WidgetDatePicker.vue";
import { BSpinner } from "bootstrap-vue";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import WidgetService from "@/services/dashboard/widgets/widgetService-service";
import { VBTooltip } from "bootstrap-vue";
import BarChart from "@/components/charts/BarChart.vue";
export default {
  props: {
    settings: {
      required: true,
    },
  },
  components: {
    WidgetDatePicker,
    BSpinner,
    VBTooltip,
    BarChart,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  async created() {
    this.loading = true;
    try {
      this.services = new WidgetService();
      await this.fetchLocations({ include: ["infoCards"] });
      await this.fetchWidget();
    } finally {
      this.loading = false;
    }
  },
  data() {
    return {
      loading: false,
      service: null,
      values: null,
      chartGrid: {
        bottom: 30,
        left: 0,
        top: 0,
        right: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      locations: "locationsOld/list",
    }),
    periodeFilterSetting() {
      if (
        this.settings.periodeFilter == undefined ||
        this.settings.periodeFilter.showLastTwelvMonths
      ) {
        let toDate = new Date();
        let fromDate = new Date();
        fromDate = fromDate.setFullYear(toDate.getFullYear() - 1);

        return {
          periode: [
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
          ],
          showLastTwelvMonths: true,
        };
      } else {
        return this.settings.periodeFilter;
      }
    },

    fromDate() {
      return this.periodeFilterSetting.periode[0];
    },

    toDate() {
      return this.periodeFilterSetting.periode[1];
    },

    fromDateDisplay() {
      return moment(this.fromDate).format("MMM DD, YYYY");
    },
    toDateDisplay() {
      return moment(this.toDate).format("MMM DD, YYYY");
    },
    chartHeader() {
      if (!this.values) return [];

      return [
        this.$t("Generic.RentPlusCollection"),
        this.$t("Generic.Charge"),
        this.$t("Generic.TonsCollected"),
        this.$t("Generic.PricePrTon"),
      ];
    },
    chartData() {
      let series = [];
      if (!this.values) return series;
      const self = this;
      let labelOptions = {
        show: true,
        color: "#383838",
        textBorderColor: "#333333",
        textBorderWidth: 0.1,
        fontSize: 13,
        fontWeight: "bold",
        position: "insideBottom",
        distance: 15,
        align: "left",
        verticalAlign: "middle",
        rotate: 90,
        formatter: function (params) {
          return self.$options.filters.toCurrency(params.data);
        },
      };

      let currentPeriode = {
        type: "bar",
        itemStyle: {
          color: "#407e3d",
          borderRadius: [5, 5, 0, 0],
        },

        barGap: 0,
        label: labelOptions,
        data: [
          this.values.currentPeriode.rentCost +
            this.values.currentPeriode.collectionCost,
          this.values.currentPeriode.chargeCost,
          this.values.currentPeriode.tonsCollected,
          this.values.currentPeriode.pricePrTons,
        ],
      };

      let lastPeriode = {
        type: "bar",
        itemStyle: {
          color: "#B8B8B8",
          borderRadius: [5, 5, 0, 0],
        },
        label: labelOptions,
        data: [
          this.values.lastYearPeriode.rentCost +
            this.values.lastYearPeriode.collectionCost,
          this.values.lastYearPeriode.chargeCost,
          this.values.lastYearPeriode.tonsCollected,
          this.values.lastYearPeriode.pricePrTons,
        ],
      };
      series.push(currentPeriode, lastPeriode);

      return series;
    },
  },

  methods: {
    ...mapActions({
      fetchLocations: "locationsOld/fetchList",
    }),
    async fetchWidget() {
      if (!this.fromDate || !this.toDate) return;
      try {
        this.loading = true;
        let location = null;
        if (this.settings.selectedLocation != null) {
          location = this.settings.selectedLocation.id;
        }
        let resonse = await this.services.getDataWithLocation(
          "/financial-overview/",
          location,
          this.fromDate,
          this.toDate
        );
        this.values = resonse;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-container {
  height: 100%;
  justify-content: space-between;
  padding: 10px;
  .content-container {
    height: 100%;
    text-align: center;
    justify-content: center;
    .result {
      font-size: 6rem;
      font-weight: bold;
      color: #407e3d;
      line-height: 6rem;
      &.Economic {
        font-size: 3rem;
        line-height: 4rem;
      }
    }
    .result-text {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
  .topbar {
    > div {
      width: 50%;
    }
    .radios {
      > * {
        input {
          margin-right: 3px;
        }
        padding: 0 5px;
      }
    }
  }
}
.bar-container {
  width: 100%;
  height: 100%;
}
</style>