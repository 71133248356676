<template>
  <v-chart :autoresize="true" class="chart" :option="options" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  props: {
   options:{
    required: true
   }
  },
  data() {
    return {};
  },
  components: {
    VChart,
  },
  computed: {
    labelOptions() {
      return;
    },
  },
};
</script>

<style>
</style>