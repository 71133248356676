<template>
  <div class="posdting-overview-widget-container d-flex flex-column">
    <div>
      <div class="topbar d-flex">
        <div>
          <div class="periode">{{ fromDateDisplay }} - {{ toDateDisplay }}</div>
        </div>
        <div class="d-flex justify-content-end">
          <widget-date-picker
            v-model="settings.periodeFilter"
            @change="fetchWidget()"
          />
        </div>
      </div>
      <treeselect
        class="mt-1"
        :multiple="true"
        :options="infocardsOptions"
        v-model="settings.infocards"
        :value-consists-of="'LEAF_PRIORITY'"
        @input="fetchWidget()"
        placeholder="Filter"
      />
    </div>
    <div class="table-content">
      <b-tabs
        class="h-100"
        v-if="!loading"
        @input="(value) => (settings.viewMode = value == 0 ? 'TABLE' : 'BAR')"
        content-class="content-tab-inner"
      >
        <b-tab
          :title="$t('Widgets.PostingsOverviewWidget.Table')"
          :active="settings.viewMode == 'TABLE'"
          @input="settings.viewMode = 'TABLE'"
        >
          <b-table
            responsive
            :fields="tableFields"
            :items="tableItems"
            show-empty
            :empty-text="$t('Generic.InvoiceListEmpty')"
          >
            <template #cell(postingCategory)="data">
              {{ $t(`Generic.${data.value}`) }}
            </template>
            <template #cell(quantity)="data">
              {{ data.value | toCurrency }}
            </template>
          </b-table>
        </b-tab>
        <b-tab
          :title="$t('Widgets.PostingsOverviewWidget.BarChart')"
          :active="settings.viewMode == 'BAR'"
          @input="settings.viewMode = 'BAR'"
        >
          <bar-chart
            :grid="chartGrid"
            :axisLabel="axisLabel"
            :chartHeaders="chartHeaders"
            :chartSeries="chartValues"
          ></bar-chart>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import WidgetService from "@/services/dashboard/widgets/widgetService-service";
import WidgetDatePicker from "../components/WidgetDatePicker.vue";
import moment from "moment";
import { BOverlay, BTable, BTabs, BTab } from "bootstrap-vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import BarChart from "@/components/charts/BarChart.vue";

export default {
  props: {
    settings: {
      required: true,
    },
  },
  components: {
    Treeselect,
    WidgetDatePicker,
    BOverlay,
    PageLoader,
    BTable,
    BTabs,
    BTab,
    BarChart,
  },
  async created() {
    await this.fetchLocations({ include: ["infoCards"] });
    await this.fetchWidget();
  },
  data() {
    return {
      loading: false,
      data: null,
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 55,
        overflow: "truncate",
        width: "60",
      },
      chartGrid: {
        bottom: 80,
        left: 0,
        top: 0,
        right: 0,
      },
    };
  },
  computed: {
    ...mapGetters({ locations: "locationsOld/list" }),
    tableFields() {
      return [
        {
          key: "postingCategory",
          label: this.$t("Widgets.PostingsOverviewWidget.PostingCategory"),
          sortable: false,
        },
        {
          key: "quantity",
          label: this.$t("Widgets.PostingsOverviewWidget.PostedAmount"),
          sortable: false,
        },
      ];
    },
    tableItems() {
      return this.data != null ? this.data.postingCounts : [];
    },
    infocardsOptions() {
      if(this.locations == null) return [];
      return this.locations.map((location) => {
        return {
          id: location.id,
          label: location.name,
          children: location.infoCards.map((infocard) => {
            return {
              id: infocard.id,
              label: infocard.name,
            };
          }),
        };
      });
    },
    periodeFilterSetting() {
      if (
        this.settings.periodeFilter == undefined ||
        this.settings.periodeFilter.showLastTwelvMonths
      ) {
        let toDate = new Date();
        let fromDate = new Date();
        fromDate = fromDate.setFullYear(toDate.getFullYear() - 1);

        return {
          periode: [
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
          ],
          showLastTwelvMonths: true,
        };
      } else {
        return this.settings.periodeFilter;
      }
    },

    fromDate() {
      return this.periodeFilterSetting.periode[0];
    },

    toDate() {
      return this.periodeFilterSetting.periode[1];
    },

    fromDateDisplay() {
      return moment(this.fromDate).format("MMM DD, YYYY");
    },
    toDateDisplay() {
      return moment(this.toDate).format("MMM DD, YYYY");
    },
    chartHeaders() {
      if (this.data == null) return [];
      return this.data.postingCounts.map((a) =>
        this.$t(`Generic.${a.postingCategory}`)
      );
    },
    chartValues() {
      const self = this;
      let series = [
        {
          type: "bar",
          stack: "total",
          itemStyle: {
            color: "#407e3d",
            borderRadius: [5, 5, 0, 0],
          },
          label: {
            show: true,
            color: "#383838",
            textBorderColor: "#333333",
            textBorderWidth: 0.1,
            fontSize: 13,
            fontWeight: "bold",
            position: "insideBottom",
            distance: 15,
            align: "left",
            verticalAlign: "middle",
            rotate: 90,
            formatter: function (params) {
              return self.$options.filters.toCurrency(params.data);
            },
          },
          data:
            this.data != null
              ? this.data.postingCounts.map((a) => a.quantity)
              : [],
        },
      ];
      return series;
    },
  },
  methods: {
    ...mapActions({ fetchLocations: "locationsOld/fetchList" }),
    async fetchWidget() {
      try {
        const service = new WidgetService();
        this.loading = true;
        let response = await service.getPostingsOverviewSimulationWidget(
          this.fromDate,
          this.toDate,
          this.settings.infocards
        );
        this.data = response.data.data;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    periodeFilterSetting(newValue, oldValue) {
      if (newValue != oldValue) this.fetchWidget();
    },
  },
};
</script>
<style lang="scss" scoped>
.posdting-overview-widget-container {
  height: 100%;
  justify-content: space-between;
  padding: 10px;

  .topbar {
    > div {
      width: 50%;
    }
    .radios {
      > * {
        input {
          margin-right: 3px;
        }
        padding: 0 5px;
      }
    }
  }

  .table-content {
    height: 100%;
    margin-top: 1rem;
  }
}
.bar-container {
  height: 100%;
  width: 100%;
}
.table-con {
  position: relative;
  height: calc(100%-10px);
  overflow-y: auto;
}
</style>

<style lang="scss">
.vue-treeselect__multi-value-item,
.vue-treeselect__value-remove {
  color: #407e3d !important;
}
[dir] .vue-treeselect__multi-value-item {
  background: rgba(64, 126, 61, 0.12);
}
.content-tab-inner {
  height: calc(100% - 40px);
  overflow-y: auto;
}
.h-100-minus-menu {
  height: calc(100% - 38px);
}
.tab-pane {
  height: 100%;
}
</style>




















































