<template>
  <div class="widget-container d-flex flex-column">
    <div class="topbar d-flex">
      <div>
        <div class="periode">{{ fromDateDisplay }} - {{ toDateDisplay }}</div>
        <h1 class="header">
          {{ $t("Widgets.WasteDistributionPyramidWidget.Headline") }}
        </h1>
      </div>
      <div class="d-flex justify-content-end">
        <widget-date-picker v-model="settings.periodeFilter" />
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div
        v-if="loading || distributionData == null"
        class="d-flex justify-content-center align-items-center h-100"
      >
        <b-spinner />
      </div>
      <div class="pyramid-container" v-else>
        <div class="pyramid">
          <div class="row">
            <div class="col-12 col-md-6 bar-wrap">
              <div class="bar">
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#438651" />
                    <path
                      d="M16.2177 13.2099L16.8544 7.34195C16.9268 6.67428 16.3336 6.12642 15.6738 6.2516L10.2192 7.28644C6.15926 8.05669 4.69404 13.1934 7.31485 16.21C8.04194 14.5733 9.56351 12.242 11.7998 10.6437C9.8016 14.487 9.13874 16.0096 8.93893 17.4813C12.0015 19.0249 15.8083 16.9832 16.2177 13.2099Z"
                      fill="white"
                    />
                  </svg>
                  {{
                    $t("Widgets.WasteDistributionPyramidWidget.ReuseCategory")
                  }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6 info">
              <span
                >{{ distributionData.reusePercentage | toCurrency }} [%]
              </span>
              <span>{{ distributionData.reuseTons | toCurrency }} [tons] </span>
              <span
                v-b-tooltip.hover.top="
                  `Transport: ${distributionData.reuseTransportCO2.toFixed(
                    2
                  )} [CO2]`
                "
                >{{
                  (distributionData.reuseCO2 +
                    distributionData.reuseTransportCO2)
                    | toCurrency
                }}
                [CO2]
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 bar-wrap">
              <div class="bar">
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#3C7A49" />
                    <path
                      d="M13.9695 10.7258H16.9999V7.73099"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 8.60812C7.51591 8.09828 8.12838 7.69386 8.80244 7.41794C9.47651 7.14201 10.199 7 10.9286 7C11.6582 7 12.3806 7.14201 13.0547 7.41794C13.7288 7.69386 14.3412 8.09828 14.8571 8.60812L17 10.7258"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.0305 13.2551H7V16.2499"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 15.3728C16.4841 15.8826 15.8716 16.2871 15.1976 16.563C14.5235 16.8389 13.801 16.9809 13.0714 16.9809C12.3418 16.9809 11.6194 16.8389 10.9453 16.563C10.2712 16.2871 9.65876 15.8826 9.14286 15.3728L7 13.2552"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {{
                    $t("Widgets.WasteDistributionPyramidWidget.RezyclCategory")
                  }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6 info">
              <span
                >{{ distributionData.rezyclPercentage | toCurrency }} [%]
              </span>
              <span
                >{{ distributionData.rezyclTons | toCurrency }} [tons]
              </span>
              <span
                v-b-tooltip.hover.top="
                  `Transport: ${distributionData.rezyclTransportCO2.toFixed(
                    2
                  )} [CO2]`
                "
                >{{
                  (distributionData.rezyclCO2 +
                    distributionData.rezyclTransportCO2)
                    | toCurrency
                }}
                [CO2]
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 bar-wrap">
              <div class="bar">
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#305E38" />
                    <path
                      d="M10.5 10.8L8.34886 9.14263C7.58199 10.3458 7 11.7647 7 13.3C7 14.6261 7.52678 15.8978 8.46447 16.8355C9.40215 17.7732 10.6739 18.3 12 18.3C13.3261 18.3 14.5979 17.7732 15.5355 16.8355C16.4732 15.8978 17 14.6261 17 13.3C17 8.79999 12.5 5.29999 12.5 5.29999L10.5 10.8Z"
                      fill="white"
                    />
                  </svg>
                  {{
                    $t(
                      "Widgets.WasteDistributionPyramidWidget.CombostionCategory"
                    )
                  }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6 info">
              <span>
                {{ distributionData.combostionPercentage | toCurrency }} [%]
              </span>
              <span
                >{{ distributionData.combostionTons | toCurrency }} [tons]
              </span>
              <span
                v-b-tooltip.hover.top="
                  `Transport: ${distributionData.combostionTransportCO2.toFixed(
                    2
                  )} [CO2]`
                "
                >{{
                  (distributionData.combostionCO2 +
                    distributionData.combostionTransportCO2)
                    | toCurrency
                }}
                [CO2]
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 bar-wrap">
              <div class="bar">
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#234328" />
                    <path
                      d="M10 11H12V18"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8Z"
                      fill="white"
                    />
                  </svg>
                  {{
                    $t("Widgets.WasteDistributionPyramidWidget.SpecialCategory")
                  }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6 info">
              <span
                >{{ distributionData.specialPercentage | toCurrency }} [%]
              </span>
              <span
                >{{ distributionData.specialTons | toCurrency }} [tons]
              </span>
              <span
                v-b-tooltip.hover.top="
                  `Transport: ${distributionData.specialTransportCO2.toFixed(
                    2
                  )} [CO2]`
                "
                >{{
                  (distributionData.specialCO2 +
                    distributionData.specialTransportCO2)
                    | toCurrency
                }}
                [CO2]
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 bar-wrap">
              <div class="bar">
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#16291A" />
                    <path
                      d="M17.9783 15C18.1817 15 18.2999 14.7702 18.1817 14.6047L13.6675 8.28481C13.5678 8.14522 13.3604 8.14522 13.2607 8.28481L11.4053 10.8824L12.4641 13L11.147 11.2439L11.1405 11.2531L9.66468 9.26958C9.56471 9.13522 9.36349 9.13522 9.26353 9.26958L5.29703 14.6008C5.17433 14.7657 5.29204 15 5.49761 15H17.9783Z"
                      fill="white"
                    />
                  </svg>
                  {{
                    $t(
                      "Widgets.WasteDistributionPyramidWidget.LandfillCategory"
                    )
                  }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6 info">
              <span
                >{{ distributionData.landfillPercentage | toCurrency }} [%]
              </span>
              <span
                >{{ distributionData.landfillTons | toCurrency }} [tons]
              </span>
              <span
                v-b-tooltip.hover.top="
                  `Transport: ${distributionData.landfillTransportCO2.toFixed(
                    2
                  )} [CO2]`
                "
                >{{ distributionData.landfillCO2 + distributionData.landfillTransportCO2 | toCurrency }} [CO2]
              </span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { BFormDatepicker, BSpinner } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import WidgetDatePicker from "@/views/dashboard/components/WidgetDatePicker.vue";
import moment from "moment";
import WasteDistributionPyramidWidgetService from "@/services/dashboard/widgets/wasteDistributionPyramidWidget-service";
import { VBTooltip } from "bootstrap-vue";

export default {
  props: {
    settings: {
      required: true,
    },
  },
  components: {
    BFormDatepicker,
    BSpinner,
    WidgetDatePicker,
    BSpinner,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      distributionData: null,
      loading: false,
    };
  },
  created() {
    this.fetchWidget();
    //if (this.distributionData == undefined) this.fetchDistributionData();
  },

  computed: {
    ...mapGetters({
      fromDateFilter: "wasteDistributionPyramidWidget/getFromDateFilter",
      toDateFilter: "wasteDistributionPyramidWidget/getToDateFilter",
    }),
    periodeFilterSetting() {
      if (
        this.settings.periodeFilter == undefined ||
        this.settings.periodeFilter.showLastTwelvMonths
      ) {
        let toDate = new Date();
        let fromDate = new Date();
        fromDate = fromDate.setFullYear(toDate.getFullYear() - 1);

        return {
          periode: [
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
          ],
          showLastTwelvMonths: true,
        };
      } else {
        return this.settings.periodeFilter;
      }
    },
    fromDate() {
      return this.periodeFilterSetting.periode[0];
    },

    toDate() {
      return this.periodeFilterSetting.periode[1];
    },

    fromDateDisplay() {
      return moment(this.fromDate).format("MMM DD, YYYY");
    },
    toDateDisplay() {
      return moment(this.toDate).format("MMM DD, YYYY");
    },
  },

  methods: {
    ...mapActions({
      fetchDistributionData:
        "wasteDistributionPyramidWidget/fetchDistributionData",
      setFromDateFilter: "wasteDistributionPyramidWidget/updateFromDateFilter",
      setToDateFilter: "wasteDistributionPyramidWidget/updateToDateFilter",
    }),
    getTonsFromKg(kg) {
      return (kg / 1000).toFixed(3);
    },
    toDateUpdate(value) {
      this.toDate = value;
    },
    fromDateUpdate(value) {
      this.fromDate = value;
    },
    async fetchWidget() {
      try {
        this.loading = true;
        const service = new WasteDistributionPyramidWidgetService();
        this.distributionData = await service.getDistributionData(
          this.fromDate,
          this.toDate
        );
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    periodeFilterSetting() {
      this.fetchWidget();
    },
  },
};
</script>


<style lang="scss" scoped>
.widget-container {
  height: 100%;
  justify-content: space-between;
  padding: 10px;
  .topbar {
    > div {
      width: 50%;
    }
    .radios {
      > * {
        input {
          margin-right: 3px;
        }
        padding: 0 5px;
      }
    }
  }

  .bar-container {
    height: 100%;
    padding: 10px 30px;
    .bar-content {
      height: 100%;
      align-items: center;
      justify-content: flex-end;
    }
    > div {
      width: 80px;
      height: 100%;
      justify-content: space-between;
      margin-right: 20px;
      text-align: center;
      align-items: center;
    }
    .bar {
      &.zero {
        background-color: #ccc;
        height: 1%;
      }
      width: 40px;
      background-color: #407e3d;
      border-radius: 5px;
    }
    .value {
      margin-bottom: 10px;
    }
    .name {
      font-size: 12px;
      font-weight: bold;
    }
  }
}
.pyramid-container {
  background: #f9f9f9;
  border-radius: 6px;
  padding: 30px 16px;
  height: 100%;
  display: flex;
  align-items: center;
}
.pyramid {
  min-width: 850px;
  width: 100%;

  .row {
    margin-bottom: 24px;

    .icon {
      span {
        font-weight: 500;
        margin-left: 16px;
        display: inline-block;
        @media (max-width: 991px) {
          font-size: 12px;
        }
      }

      @media (max-width: 767px) {
        order: 1;
      }
    }

    .info {
      justify-content: flex-end;
      display: flex;
      margin: 0 -8px;
      align-items: center;
      span {
        background: #dddddd;
        border-radius: 12px;
        font-weight: bold;
        padding: 0 12px;
        line-height: 24px;
        font-size: 14px;
        font-weight: normal;
        display: inline-block;
        margin: 0 8px;
        @media (max-width: 991px) {
          font-size: 10px;
          padding: 0 6px;
          margin: 0 6px;
        }
      }
      @media (max-width: 767px) {
        order: 2;
        justify-content: flex-start;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .bar-wrap {
      @media (max-width: 767px) {
        order: 3;
      }
      .bar {
        span {
          display: flex;
          align-items: center;
        }
        text-align: center;
        color: #fff;
        display: flex;
        background: rgba(67, 134, 81, 1);
        margin: 0 auto;
        border-radius: 6px;
        height: 32px;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        @media (max-width: 991px) {
          font-size: 10px;
        }
        @media (max-width: 767px) {
          margin: 0;
        }
      }
    }

    &:nth-child(2) {
      .bar {
        background-color: #3c7a49;
        max-width: 80%;
      }
    }
    &:nth-child(3) {
      .bar {
        background-color: #305e38;
        max-width: 60%;
      }
    }
    &:nth-child(4) {
      .bar {
        background-color: #234328;
        max-width: 40%;
      }
    }
    &:nth-child(5) {
      .bar {
        background-color: #16291a;
        max-width: 20%;
      }
    }
  }
}

.dark-layout {
  .pyramid {
    background-color: #283046;
    .info {
      span {
        background: transparent;
      }
    }
  }
}
</style>


<style lang="scss" scoped>
span.refresh {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 7px;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
}

.overview {
  padding: 16px;

  .header {
    margin-bottom: 10px;
    .period-picker {
      display: flex;
      align-items: center;
    }
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      color: #000000;
      margin-bottom: 16px;
    }
  }
}
</style>