import BaseService from "@/services/base/base.service";
import moment from 'moment'

export default class WidgetService extends BaseService {
    constructor() {
        super('widgets')
    }

    async getDataWithLocation(type, location, fromDate, toDate) {
        return (await super.get(type, { locationId: location, fromDate: moment(fromDate).format('YYYY-MM-DD'), toDate: moment(toDate).format('YYYY-MM-DD') })).data.data;
    }

    async getData(type, fromDate, toDate) {
        return (await super.get(type, { fromDate: moment(fromDate).format('YYYY-MM-DD'), toDate: moment(toDate).format('YYYY-MM-DD') })).data.data;
    }

    async getDistributionSimulationWidget(fromDate, toDate, locationId = null, wasteCategory) {
        return (await super.get('waste-distribution-simulator', { fromDate: moment(fromDate).format('YYYY-MM-DD'), toDate: moment(toDate).format('YYYY-MM-DD'), locationId: locationId, wasteCategory: wasteCategory }))
    }

    async getPostingsOverviewSimulationWidget(fromDate, toDate, infocards) {
        return (await super.get('posting-overview', { fromDate: moment(fromDate).format('YYYY-MM-DD'), toDate: moment(toDate).format('YYYY-MM-DD'), infocards: infocards }))
    }

    async getFractionPriceHistoryWidget(fromDate, toDate, locations, wasteFractions, postingCategory) {
        return (await super.get('fraction-price-history', { 
            fromDate: moment(fromDate).format('YYYY-MM-DD'), 
            toDate: moment(toDate).format('YYYY-MM-DD'),
            locationIds: locations,
            wasteFractionIds: wasteFractions,
            postingCategory: postingCategory
        }))
    }
}